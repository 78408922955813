import React from 'react';
import BitmovinPlayer from "../../../../Components/Bitmovin";
import {Stack} from "@mui/material";
import {addBoolRow, addRow} from "../InfoTable";

const VideoInfoPlayer = ({video}) => {
    if (!video)
        return null;
    const has_video = video?.url_hls || video?.url_dash || video?.url_mp4;

    return (
        <Stack spacing={2}>
            {has_video ?
                <BitmovinPlayer
                    video={video}
                    autoplay={false}
                    controls={true}
                    showPoster={true}
                /> :

                <div className={'border border-gray-300 rounded-md py-4'}>
                    <div className={'text-center text-2xl text-gray-500'}>

                        {/*
                <i className="fas fa-video-slash text-4xl">

                </i>
                */}

                        No video available
                    </div>
                </div>
            }

            <VideoSpecs video={video}/>

            {/*
            <div className={'border border-gray-300 rounded-md py-4 h-full'}>
                <VideoSpecs video={video}/>
            </div>
            */}
        </Stack>
);
};


export const VideoSpecs = ({
    video, source
}) => {
    return (
        <div>
            <table className="table-auto w-full">
                <tbody>
                {addRow('Name:', video?.title)}
                {addRow('Slug:', video?.slug)}
                {addRow('ID:', video?.id)}
                {addRow('Duration:', video?.duration)}
                {addRow('Revision:', video?.revision)}
                {addRow('Created:', video?.created)}
                {addRow('Updated:', video?.updated)}
                {addBoolRow('Hidden:', video?.hidden)}
                {addBoolRow('Coming Soon:', video?.coming_soon)}
                {addBoolRow('New Video:', video?.new_video)}
                </tbody>
            </table>
        </div>
    );
};


export default VideoInfoPlayer;