import React from 'react';
import {Outlet} from "react-router-dom";
import Navbar from "./Navbar";

const Layout = ({title, children}) => {
    return (
        <div id={'main-layout'} className={'h-screen flex flex-col'}>
            <Navbar title={title ?? "Muzology Studio"} />
            <div id={'main-contents'} className={'flex flex-grow bg-red-200- bg-gray-50'}>
                {children}
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
