import React from 'react';
import {createSourceConfig, hasSourceConfig} from "../../Components/Bitmovin/BitmovinPlayer";
import BitmovinPlayer from "../../Components/Bitmovin";

const VideoPlayer = ({video, className = ''}) => {
    console.log('<VideoPlayer>', video);
    if (!video || !hasSourceConfig(video)) {
        return (
            <div className={'border border-gray-300 rounded-md py-4'}>
                <div className={'text-center text-2xl text-gray-500'}>
                    No video available
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <BitmovinPlayer
                source={createSourceConfig(video)}
                // aria-label={`video player for ${video.title}`}
                video={video}
                // user={user}
                // assignment={assignment}
                // key={sessionId}
                // className='sticky-video'
                autoplay={false}
                controls={true}
                // sources={sources}
                // poster={createVideoPoster(video?.url_thumbnail ?? '')}
                // metaData={metaData}
                // onReady={onVideoStart}
                // onPlay={onVideoPlay}
                // onEnd={onVideoEnd}
                // postVideoSessionRanges={postVideoSessionRanges}
            />
        </div>
    );
};

export default VideoPlayer;