import React from 'react';
import { Outlet } from "react-router-dom";
import { useGetAPI } from "../../context/api";
import useSWR from 'swr'
import { useStudio } from "../Context/StudioContext";

export const VideosContext = React.createContext([]);

const VideosContainer = (props) => {
    // const [videos, setVideos] = React.useState([]);
    // const { isLoading, error, data } = useSWR('/api/video/');
    // const videos = data?.data ?? [];
    const {videos, mutateVideos} = useStudio();

    // const { loading:isLoading, error, data } = useGetAPI('/api/video/');
    // const videos = data ?? [];
    // console.log(data);

    // if (isLoading)
    //     return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;

    // const videos = useVideos();
    // const video = videos?.find(video => video.id === videoId);
    // const { data: lesson } = useGetAPI(video && `/api/lessons/${video?.lesson_id}/`);
    // const { data: quiz } = useGetAPI(video && `/api/quiz/${video?.quiz_id}/`);

    return (
        <VideosContext.Provider value={{videos, mutateVideos}}>
            {!videos && <div>Loading...</div>}
            <Outlet />
        </VideosContext.Provider>
    );
};

export function useVideos() {
    const context = React.useContext(VideosContext);
    if (context === undefined) {
        throw new Error(`useVideos must be used within a VideosContainer`)
    }
    return context.videos;
}

export function useVideo(videoId) {
    const context = React.useContext(VideosContext);
    if (context === undefined) {
        throw new Error(`useVideo must be used within a VideosContainer`)
    }

    const videos = context.videos;
    const mutateVideos = context.mutateVideos;
    console.log('useVideo', videoId, videos, mutateVideos);
    // fire an API request to get the video by id
    const { data } = useSWR(`/api/video/${videoId}/`,
        {
            initialData: videos?.find(video => video.id === videoId)
        });
    console.log('useVideo data:', data);
    return data?.data ?? null;
    // const { data: video } = useGetAPI(`/api/video/${videoId}/`);
    // return videos?.find(video => video.id === videoId);
}

export default VideosContainer;
