import React, {useCallback, useEffect, useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {Button, Card} from "@mui/material";
import {
    QUESTION_TYPE_CHOICES,
    QUESTION_TYPE_MULTIPLE_CHOICE,
    QUIZ_COLORS,
    QUIZ_LEVEL_CHOICES,
    QUIZ_LEVELS
} from "../constants";
import RichText from "../../../../Components/RichText/RichText";
import apiClient from "../../../../context/APIClient";
import {useQuizEditor} from "../QuizEditorProvider";
import {QUESTION_TYPE_MULTIPLE_SELECT} from "../QuestionSettingsDialog";
import {useDevTool} from "../../../../context/DevTool";

const QuizVersions = ({quiz}) => {
    const [versions, setVersions] = useState([]);

    useDevTool('versions', versions);

    useEffect(() => {
        if (!quiz) {
            return;
        }
        apiClient.get(`/api/quiz/${quiz.id}/versions/`)
            .then(response => {
                console.log('response', response);
                const vers = response.data;
                setVersions(vers);
            })
            .catch(error => {
                console.log('error', error);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('quiz versions', versions);

    // create the rows
    const rows = versions?.reduce((acc, version) => {
        acc = acc.concat({
            id: version.id,
            version: version.version,
            date: version.date,
            hash: version.hash,
            current: version.current,
            published: version.published,
            published_date: version.published_on,
        });
        return acc;
    }, []);


    const columns = [
        // {
        //     field: 'id',
        //     headerName: 'ID',
        //     headerAlign: 'center',
        //     width: 200,
        //     cellClassName: 'font-bold cursor-pointer select-none',
        //     align: 'left',
        //     renderCell: (params) => {
        //         const level = params.row.level;
        //         const title = QUIZ_LEVELS[level];
        //         const color = QUIZ_COLORS[level];
        //
        //         // construct a description
        //         let description = `${title} Question ${params.row.question_index + 1}`;
        //
        //         // if the choice index exists
        //         if (params.row.choice_index) {
        //             description = `${description} Choice ${params.row.choice_index}`;
        //         }
        //         return <div className={`font-bold  ${color}`}>{description}</div>;
        //     }
        // },
        /*
        {
            field: 'level',
            headerName: 'Level',
            headerAlign: 'center',
            // width: 100,
            cellClassName: 'font-bold cursor-pointer select-none',
            align: 'center',
            renderCell: (params) => {
                if (params.value === 0)
                    return <div className={'font-bold text-gray-600'}>Silver</div>;
                if (params.value === 1)
                    return <div className={'font-bold text-yellow-500'}>Gold</div>;
                if (params.value === 2)
                    return <div className={'font-bold text-blue-500'}>Platinum</div>;
                return <div className={'font-bold'}>{params.value}</div>
            }
        },
        {
            field: 'question_index',
            headerName: 'Question #',
            // width: 100,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                return params.value + 1;
                // if (params.value === 0)
                //     return <div className={'font-bold text-gray-600'}>Silver</div>;
                // if (params.value === 1)
                //     return <div className={'font-bold text-yellow-500'}>Gold</div>;
                // if (params.value === 2)
                //     return <div className={'font-bold text-blue-500'}>Platinum</div>;
                // return <div className={'font-bold'}>{params.value}</div>
            }

        },
        {
            field: 'choice_index',
            headerName: 'Choice #',
            // width: 100,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                if (params.value)
                    return params.value;
                return '';
                // if (params.value === 0)
                //     return <div className={'font-bold text-gray-600'}>Silver</div>;
                // if (params.value === 1)
                //     return <div className={'font-bold text-yellow-500'}>Gold</div>;
                // if (params.value === 2)
                //     return <div className={'font-bold text-blue-500'}>Platinum</div>;
                // return <div className={'font-bold'}>{params.value}</div>
            }

        },
        {
            field: 'type',
            headerName: 'Type',
            width: 160,
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                // find the choice title with the given value
                const choice = QUESTION_TYPE_CHOICES.find((choice) => choice[0] === params.value)
                if (choice)
                    return choice[1];
                return params.value;
                // return choice[1];
                // QUESTION_TYPE_CHOICES.map((choice) => {
                //     if (choice[0] === params.value)
                //         return choice[1];
                // }
            }
        },
        */
        // {
        //     field: 'question_text',
        //     headerName: 'Question',
        //     flex: 1,
        //     cellClassName: 'font-normal cursor-pointer select-none',
        // },
        // { field: 'question_index', headerName: 'Question' },
        // { field: 'choice_index', headerName: 'Choice' },
        // { field: 'type', headerName: 'Type', width: 140 },
        {
            field: 'version',
            headerName: 'Version',
            renderCell: (params) => {
                return <div className={'font-bold text-center w-full'}>{params.value}</div>;
            }
            // flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            // flex: 1,
            width: 200,
            renderCell: (params) => {
                // format the date
                return <div className={'font-normal text-center w-full'}>
                    {params.value ? new Date(params.value).toLocaleString() : ''}
                </div>;
            }
        },
        {
            field: 'current',
            headerName: 'Current',
            // flex: 1
            renderCell: (params) => {
                if (params.value)
                    return <div className={'font-bold text-green-500'}>Yes</div>;
                return <div className={'font-bold text-red-500'}>No</div>;
            }
        },
        {
            field: 'published',
            headerName: 'Published',
            renderCell: (params) => {
                if (params.value)
                    return <div className={'font-bold text-green-500'}>Yes</div>;
                return <div className={'font-bold text-red-500'}>No</div>;
            }
        },
        {
            field: 'published_date',
            headerName: 'Published Date',
            flex: 1,
            renderCell: (params) => {
                // format the date
                return <div className={'font-normal text-center w-full'}>
                    {params.value ? new Date(params.value).toLocaleString() : ''}
                </div>;
            }
        },
        { field: 'hash', headerName: 'Hash', flex: 1 },


        // {
        //     id: 'regenerate',
        //     headerName: 'Regenerate',
        //     flex: 0,
        //     renderCell: (params) => {
        //         return (
        //             <div className={'flex flex-row gap-2'}>
        //                 <button
        //                     className={'font-bold text-blue-500 cursor-pointer'}
        //                     onClick={() => regenerateAudio(params.row.question, params.row.choice)}
        //                 >
        //                     Regenerate
        //                 </button>
        //             </div>
        //         );
        //     }
        // },
    ];

    const onRowClick = (event) => {
        console.log('onRowClick', event);
    }

    const handleAddVersion = useCallback(() => {
        if (!quiz) {
            return;
        }

        apiClient.post(`/api/quiz/${quiz.id}/versions/`)
            .then(response => {
                console.log('response', response);
                const newVersion = response.data;
                setVersions(items => [...items, newVersion]);
            })
            .catch(error => {
                console.log('error', error);
            })
    }, [quiz?.id]);

    return (
        <Card className={'flex-grow h-full'}>
            <div className={'flex flex-row gap-4'}>
                <Button className={'btn-primary'} onClick={handleAddVersion}>
                    Add Version
                </Button>
            </div>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                autoHeight={true}
                // hideFooter={true}

                // showBorders={true}
                // columnAutoWidth={true}
                // columnHidingEnabled={true}
                // showColumnLines={true}
                // showRowLines={true}
                // rowAlternationEnabled={true}
                // onRowUpdating={props.onRowUpdating}
                // onRowInserting={props.onRowInserting}
                // onRowRemoving={props.onRowRemoving}
                // onRowPrepared={props.onRowPrepared}
                // onCellPrepared={props.onCellPrepared}
                // onEditingStart={props.onEditingStart}
                // onEditorPreparing={props.onEditorPreparing}
                // onInitNewRow={props.onInitNewRow}
                // onToolbarPreparing={props.onToolbarPreparing}
                // onRowValidating={props.onRowValidating}
                // onRowUpdated={props.onRowUpdated}

            />
        </Card>
    );
};


export default QuizVersions;
