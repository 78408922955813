import React, {useMemo} from 'react';
import {Container, Grid, Stack} from "@mui/material";
import ChatStream from "../../../../../openai/ChatStream/ChatStream";
import {QUESTION_TYPE_CHOICES} from "../../constants";
import QuizQuestionView from "./Views/QuizQuestionView";
import {AnswerKeyQuestion} from "../../AnswerKey/AnswerKeyQuestion";

const response = {
    // "id": "fb2de899-ac09-4d51-b3e6-7cf9523feb6d",
    // "quiz_id": "94e6e42a-cd8b-485e-b3b3-8f054a1bf189",
    // "created": "2023-08-31T04:11:20.855144",
    // "updated": "2023-08-31T05:32:18.574820",
    // "audio": null,
    // "audio_tts_override": null,
    "type": 1,
    "level": 1,
    // "random_position": true,
    // "table_json": null,
    // "warmup": false,
    "question_text": "Select <b><u>all</b></u> expressions that are completely simplified.",
    // "question_align": "center",
    // "math": "",
    // "chart_definition": null,
    // "table_data": "",
    // "image_url": "",
    // "image_classname": null,
    "question_index": 6,
    "instruction_text": "",
    "choices": [{
        // "id": "d46d853c-feae-4ef4-b4a8-c33fe1d2883e",
        "choice_text": "<math>2x^7\\over{z^{-3}}</math>",
        // "content_image": false,
        // "image_url": "",
        // "audio": null,
        // "audio_tts_override": null,
        "correct": false,
        "choice_index": 1,
        "last_position": false,
        // "question_id": "fb2de899-ac09-4d51-b3e6-7cf9523feb6d",
        // "created": "2023-08-31T04:11:20.944262",
        // "updated": "2023-08-31T05:32:18.628046",
        // "image_file": null,
        // "random_position": false,
        // "image_filename": null,
        // "chart_definition": null,
        // "table_data": ""
    }, {
        // "id": "9f960905-8961-446a-92bb-db3c1dbd6a04",
        "choice_text": "<math>13x^6 y^3</math>",
        "content_image": false,
        // "image_url": "",
        // "audio": null,
        // "audio_tts_override": null,
        "correct": true,
        "choice_index": 2,
        "last_position": false,
        "question_id": "fb2de899-ac09-4d51-b3e6-7cf9523feb6d",
        "created": "2023-08-31T04:11:20.989034",
        "updated": "2023-08-31T05:32:18.671351",
        "image_file": null,
        "random_position": false,
        "image_filename": null,
        "chart_definition": null,
        "table_data": ""
    }, {
        "id": "084fa169-4c32-4c6d-98a9-bd5ebff59a99",
        "choice_text": "<math>x^{12}\\over{ 8y^4}</math>",
        "content_image": false,
        "image_url": "",
        "audio": null,
        "audio_tts_override": null,
        "correct": true,
        "choice_index": 3,
        "last_position": false,
        "question_id": "fb2de899-ac09-4d51-b3e6-7cf9523feb6d",
        "created": "2023-08-31T04:11:21.025105",
        "updated": "2023-08-31T05:32:18.723374",
        "image_file": null,
        "random_position": false,
        "image_filename": null,
        "chart_definition": null,
        "table_data": ""
    }, {
        "id": "2372e26e-7cb1-479a-8565-920c4f7b8724",
        "choice_text": "<math>z^9\\over{z^6}</math>",
        "content_image": false,
        "image_url": "",
        "audio": null,
        "audio_tts_override": null,
        "correct": false,
        "choice_index": 4,
        "last_position": false,
        "question_id": "fb2de899-ac09-4d51-b3e6-7cf9523feb6d",
        "created": "2023-08-31T04:11:21.063432",
        "updated": "2023-08-31T05:32:18.771834",
        "image_file": null,
        "random_position": false,
        "image_filename": null,
        "chart_definition": null,
        "table_data": ""
    }],
    // "question_group": null,
    // "attributes": null
}

const createQuestionData = (question) => (
    {
        type: question.type,
        level: question.level,
        question_text: question.question_text,
        question_index: question.question_index,
        instruction_text: question.instruction_text,
        choices: question.choices.map((choice) => (
            {
                choice_text: choice.choice_text,
                correct: choice.correct,
                choice_index: choice.choice_index,
                last_position: choice.last_position
            }
        ))
    }
);

const codeFence = (name, value) => {
    return `\`\`\`${name}\n${value}\`\`\`\n`;
}

const codeJSON = (value) => {
    return codeFence('json', JSON.stringify(value, null, 4));
}

const basePrompt = "You are a digital assistant. " +
    "Users will ask you questions or give you tasks and you will attempt to fulfill their request. " +
    "You can respond however is helpful, such as answers, you can ask questions about " +
    "the tasks or query for deeper understandings, " +
    "offer insights or suggestions, or anything else that is useful.\n" +
    "Sometimes the user will offer data in the JSON format, if you dont understand the scheme just ask for clarification.\n" +
    "The JSON will be sent in markdown \"code fence\" format. You can respond similarly. " +
    "When you have data to return, send it via JSON. " +
    "Use a dictionary that contains a 'type' key that indicates what object type the data contains, " +
    "and a 'data' key that contains the data, and optionally a 'name' if needed, a 'filename' if it is a file, " +
    "and any other metadata useful or requested. \n" +
    "If you are asked to return any kind of data or object be sure to return it inside of a code fence.\n" +
    "You will be tasked in various subjects and domains, and you will be able to ask questions about the subject matter if needed.\n\n";


// "An example follows: " + codeJSON({"type": "person", "data": {"name": "John Doe", "age": 42}}) + "\n\n\n";

const ChatPanel = ({quiz, question}) => {
    // return (
    //     <div className='bg-red-500 h-[100px] w-full'>
    //         {children}
    //     </div>
    // );
    const systemPrompt = useMemo(() => {
        if (!quiz && !question)
            return null;

        const subjectPrompt = "For this task you are an expert at math, education, and quiz construction. Specifically middle school algebra.\n" +
            "You are a digital assistant, helping a creator of math quizzes.\n" +
            "The quiz has a type of 'standard' or 'mini', a 'standard' quiz is 10 questions a 'mini' quiz is 5 questions.\n" +
            "Each quiz is broken into 3 levels, easy, medium, and hard. So a standard quiz has 30 questions, 10 level 1, 10 level 2, and 10 level 3.\n" +
            "The levels correspond to 'silver', 'gold', and 'platinum' records in their education platform.\n" +
            "They will ask you questions about the quiz questions, and you will answer them.\n" +
            "You will also be able to ask them questions about the quiz questions, and they will answer them.\n" +
            "The current quiz will be given to you in the form of a JSON dictionary. " +
            "You can respond with modified or new questions as completed JSON dictionaries. You only need to return the 1 question if it has been modified.\n" +
            "Again, if you are asked to return a quiz or a question or any other object, do so as a JSON dictionary inside of a code fence.\n " +
            "Each quiz question has a type specified by the 'type' field and the values are as follows:" +
            "\n" + QUESTION_TYPE_CHOICES.map(([value, label]) => {
                return `${value} = ${label}\n`;
            }).join('') + "\n" +
            "\n" +
            "The quiz text and choice text can be encoded math equations. Any content you see between the `<math>KaTex Math</math>` tags are " +
            "encoded in KaTex math markup. Consider this when you encounter that kind of content. " +
            "\n" +
            "\n" +
            "The user is not interested in any technical details about the quiz format or data structure, " +
            "they are interested in the content of the quiz itself. If they ask a question about the quiz they are not asking " +
            "about the quiz data structure, they are asking about the quiz content. They are looking for help understanding the content, " +
            "possibly editing the content, or creating new content.\n\n" +
            "When asked to create new questions or new quizzes, always return the response in the quiz or question json format.\n\n";

        // create quiz data
        let prompt = null;

        let questionData = null;
        if (question) {
            console.log('creating prompt for question', question);
            questionData = createQuestionData(question);
            prompt =
                "----\n" +
                "We are currently working on a question for a quiz.\n" +
                "The quiz is titled: \"" + quiz?.title + "\"\n" +
                "The quiz description is: \"" + quiz?.description + "\"\n" +
                "The quiz question is in this JSON data:\n" +
                "```json\n" + JSON.stringify(questionData) + "\n```\n" +
                "\n" +
                "The user will now ask you a question about the quiz question, and you will answer it.\n";
        } else if (quiz && quiz.questions) {
            console.log('creating prompt for quiz', quiz);
            console.log('quiz.questions', quiz.questions);
            questionData = [];
            for (const element of quiz.questions) {
                const data = createQuestionData(element);
                questionData.push(data);
            }
            prompt =
                "----\n" +
                "We are currently working on quiz.\n" +
                "The quiz is titled: \"" + quiz?.title + "\"\n" +
                "The quiz description is: \"" + quiz?.description + "\"\n" +
                "The quiz questions are in this JSON data:\n" +
                "```json\n" + JSON.stringify(questionData) + "\n```\n" +
                "\n" +
                "The user will now ask you a question about the quiz and the quiz questions, and you will assist with anything they ask. Offer any suggestion or ask questions to get more information if you need.\n";
        } else {
            console.log('unable to create prompt for quiz or question');
        }
        console.log('prompt', prompt);
        return subjectPrompt + "\n----\n" + prompt;

    }, [quiz, question]);


    const contents = useMemo(() => {
        if( question ) {
            return (
                <QuizQuestionView
                    key={question.id}
                    question={question}
                    index={question.question_index}
                />
            );
        } else if (quiz && quiz.questions) {
            return quiz.questions.map((question, index) => (
                <QuizQuestionView
                    key={question.id}
                    question={question}
                    index={index}
                />
            ));
        }
    }, [question, quiz]);

    const contentPanel = (
        <div className={'bg-gray-300 p-2 my-10 mx-auto'}>
            {contents}
        </div>
    );

    return (
        <Container maxWidth={'lg'} className='h-full' spacing={2}>
            <Stack>

                <Grid container spacing={2} className={'w-full h-full'}>
                    <div className={'bg-gray-300 p-2 my-10 mx-auto'}>
                        {contentPanel}
                    </div>
                </Grid>

                <Grid container spacing={2} className={' h-full bg-red-300-'}>
                    <Grid item xs={12} className={' h-full bg-orange-300-'}>
                        <ChatStream
                            model={'gpt-4'}
                            systemPrompt={systemPrompt}
                        />
                        {/*
                        <div className='text-xs bg-white p-2 overflow-auto'>
                            <pre className=' text-xs bg-white p-2 overflow-auto'>
                                {systemPrompt}</pre>
                        </div>
                        */}
                    </Grid>
                </Grid>


            </Stack>
        </Container>
    );
};

export default ChatPanel;
