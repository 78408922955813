import React from 'react';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";

const EditorContainer = ({children, leftPanel = null}) => {
    return (
        <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={9}>
                <Paper>
                    {children}
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Stack spacing={2}>
                    {leftPanel}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default EditorContainer;