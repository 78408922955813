import React, { useState, useRef, useEffect } from 'react';
import RichText from "../../../../Components/RichText/RichText";

const InPlaceEditor = ({ text, onSave, children }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentText, setCurrentText] = useState(text);
    const inputRef = useRef(null);

    // Handle click outside of the input to close edit mode
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsEditing(false);
                onSave?.(currentText);
            }
        };

        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing, currentText, onSave]);

    // Handle key events for Enter, Shift+Enter, and Tab
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setIsEditing(false);
            onSave?.(currentText);
        } else if (e.key === 'Tab') {
            e.preventDefault();
            setIsEditing(false);
            onSave?.(currentText);
        }
    };

    // Render input or text based on the editing state
    return (
        <div className="">
            {isEditing ? (
                <textarea
                    ref={inputRef}
                    value={currentText}
                    onChange={(e) => setCurrentText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={() => {
                        setIsEditing(false);
                        onSave?.(currentText);
                    }}
                    autoFocus
                    // style={{width: '100%', resize: 'none'}}
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                />
            ) : (
                <button
                    onClick={() => setIsEditing(true)}
                    className="inline-block p-2 cursor-pointer hover:bg-gray-100 text-left"
                >
                    {children ?? currentText}
                    {/*<RichText text={currentText || 'Click to edit...'} placeholder={''} />*/}
                </button>
            )}
        </div>
    );
};

export default InPlaceEditor;

// Usage example
// <InPlaceEditor text="Click me to edit" onSave={(newText) => console.log('Saved text:', newText)} />

