import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {PageContainer} from "../../Home/PageContainer";
import {useVideo, useVideos} from "./VideosContainer";
import {useParams, useSearchParams, unstable_usePrompt as usePrompt, useNavigate} from "react-router-dom";
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel, Modal,
    OutlinedInput,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import VideoInfoCard from "./VideoInfoCard";
import VideoDetails from "./panels/VideoDetails";
import Box from "@mui/material/Box";
import VideoPanel from "./panels/VideoPanel";
import ThumbnailPanel from "./panels/ThumbnailPanel";
import LyricsPanel from "./panels/LyricsPanel";
import {useDevTool} from "../../context/DevTool";
import apiClient from "../../context/APIClient";
import VideoInfo from "./panels/VideoInfo";
import {useGetAPI} from "../../context/api";
import DropboxChooser from "./DropboxChooser";

import VideoHealthCheck from "./panels/VideoHealthCheck";
import SourceVideos from "./panels/SourceVideos";
import FilesPanel from "./panels/FilesPanel";
import {useStudio} from "../Context/StudioContext";
import EditorContainer from "../Editor/EditorContainer";
import TabsContainer, {TabPanel} from "../Editor/Tabs";
import {uploadFromDropbox} from "./DropboxUpload";
import VideoStorage from "./panels/VideoStorage";


// export const TabPanel = (props) => {
//     const { children, selected, value, index, ...other } = props;
//     const isSelected = selected === value;
//     return (
//         <div
//             role="tabpanel"
//             hidden={!isSelected}
//             id={`tab-panel-${index}`}
//             aria-labelledby={`tab-${index}`}
//             className={'flex-grow h-full'}
//             {...other}
//         >
//             {isSelected && (
//                 <Box sx={{ p: 2, background: '', height: '100%', position: 'relative' }}>
//                     {children}
//                     {/*
//                     <Typography>
//                     </Typography>
//                     <div className={'absolute inset-4 bg-red pb-10 flex items-stretch justify-stretch'}>
//                         {children}
//                     </div>
//                     */}
//                 </Box>
//             )}
//         </div>
//     );
// }

function useQuiz(quizId) {
    console.log('[useQuiz]', quizId)
    const {data: quiz} = useGetAPI(quizId && `/api/quiz/${quizId}/`);
    console.log('[useQuiz] Downloaded quiz', quiz);
    return quiz;
}

const VideoEditor = () => {
    const navigate = useNavigate();
    const {videoId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const uploadId = useMemo(() => searchParams ? searchParams.get('uploadId') : null, [searchParams]);

    const {lessons, mutateVideos} = useStudio();
    const sourceVideo = useVideo(videoId);

    // the original video and our working copy
    const originalVideo = useVideo(videoId);
    const [video, setVideo] = useState(null);

    // dirty data
    const [changes, setChanges] = useState({});
    const [isDirty, setIsDirty] = useState(false);

    // flags
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    //
    const [selectedSourceFile, setSelectedSourceFile] = useState(null);

    // grab the lesson and the quiz for this video
    const quiz = useQuiz(video?.quiz_id)



    const lesson = useMemo(() => {
        if (video?.lesson_id) {
            return lessons?.find(lesson => lesson.id === video.lesson_id);
        }
        return null;
    }, [video, lessons]);

    // prompt the user if they try to leave the page with unsaved changes
    usePrompt({when: isDirty, message: "You have unsaved changes. Are you sure you want to leave?",});

    console.log("<VideoEditor> quiz", quiz);
    console.log("<VideoEditor> lesson", lesson);
    useDevTool('Video Editor', {video, quiz, lesson, isDirty, originalVideo});

    // save the original video to the working copy
    useEffect(() => {
        if (originalVideo) {
            console.log("<VideoEditor> setting video", originalVideo);
            setVideo(originalVideo);
            setChanges({});
            setIsDirty(false);
        }
    }, [originalVideo]);

    // update the working copy
    const updateVideo = (update) => {
        console.log('updateVideo', update);

        // change the video state
        setVideo(value => (
            {...value, ...update}
        ));

        // save the changes
        setChanges(value => (
            {...value, ...update}
        ));

        setIsDirty(true);
    }
    // const updateLesson = (update) => {
    //     console.log('updateVideo', update);
    //     setLesson(value => (
    //         { ...value, ...update }
    //     ));
    //     // setIsDirty(true);
    // }


    // handle tab change
    // const handleTabChange = (event, value) => {
    //     setSearchParams({tab: value});
    // };


    // the current tab
    // const tab = useMemo(() => {
    //     const tab = searchParams.get('tab');
    //     return tab ?? 'info';
    // }, [searchParams]);

    // upload

    const handleSelectUpload = useCallback((upload) => {
        console.log('handleSelectUpload', upload);
        console.log('tab', searchParams.get('tab'));
        if (upload?.id) {
            setSearchParams({tab: searchParams.get('tab'), uploadId: upload.id});
        } else {
            setSearchParams({tab: searchParams.get('tab')});
        }
        // updateVideo({ upload });
    }, [searchParams, setSearchParams]);

    // save the video
    // all the tabs
    const tabs = useMemo(() => {
        return [
            {
                id: 'general',
                label: 'General',
                component: (
                    <VideoInfo
                        video={video}
                        lesson={lesson}
                        quiz={quiz}
                        updateVideo={updateVideo}
                        errors={formErrors}
                    />
                )
            },
            {
                id: 'video',
                label: 'Video',
                component: <VideoPanel video={video} updateVideo={updateVideo}/>

            },
            {
                id: 'thumbnail',
                label: 'Thumbnail',
                component: <ThumbnailPanel video={video} updateVideo={updateVideo}/>

            },
            {
                id: 'lyrics',
                label: 'Lyrics',
                component: <LyricsPanel video={video} updateVideo={updateVideo}/>
            },
            {
                id: 'source_videos',
                label: 'Source Videos',
                component: (
                    <SourceVideos
                        onSelectUpload={handleSelectUpload}
                        video={video}
                        updateVideo={updateVideo}
                        uploadId={uploadId}
                    />
                )
            },
            {
                id: 'healthcheck',
                label: 'Health Check',
                component: <VideoHealthCheck video={video}/>
            },
            {
                id: 'storage',
                label: 'Storage',
                component: (
                    <VideoStorage
                        onSelectUpload={handleSelectUpload}
                        video={video}
                        updateVideo={updateVideo}
                        uploadId={uploadId}
                    />
                )
            },
            {
                id: 'files',
                label: 'Files',
                component: <FilesPanel onSelectUpload={handleSelectUpload} video={video} updateVideo={updateVideo}
                                       uploadId={uploadId}/>
            },
            {
                id: 'details',
                label: 'Details',
                component: <VideoDetails
                    video={video}
                    lesson={lesson}
                    quiz={quiz}
                    updateVideo={updateVideo}
                />
            }
        ];
    }, [formErrors, handleSelectUpload, lesson, quiz, uploadId, video]);

    useDevTool('video', video);
    console.log(videoId, video);

    const handleSave = useCallback((stayOnPage = false) => {
        setLoading(true);
        setFormErrors({});
        // perform diff between video and originalVideo
        // function getObjectDifference(obj1, obj2) {
        //     const difference = {};
        //
        //     // Check all properties in obj1
        //     Object.keys(obj1).forEach(key => {
        //         if (obj1[key] !== obj2[key]) {
        //             difference[key] = { from: obj1[key], to: obj2[key] };
        //         }
        //     });
        //
        //     // Check all properties in obj2 that may not be in obj1
        //     Object.keys(obj2).forEach(key => {
        //         if (!(key in obj1)) {
        //             difference[key] = { from: undefined, to: obj2[key] };
        //         }
        //     });
        //
        //     return difference;
        // }

        // const diff = getObjectDifference(video, originalVideo);
        // console.log('diff', diff);
        apiClient.patch(`/api/video/${video?.id}/`, changes)
            .then((response) => {
                const {data} = response;
                setVideo(data);
                mutateVideos();
                setChanges({});
                setIsDirty(false);
                setLoading(false);
                if (!stayOnPage) {
                    navigate('/videos/');
                }
            })
            .catch((error) => {
                console.log("error saving video");
                console.dir(error);
                if (error.response.status === 400) {
                    // form validation error
                    const {data} = error.response;
                    console.log(data);
                    setFormErrors(data);
                    // setVideo(value => ({ ...value, ...data }));
                }
                setLoading(false);
            });
    }, [changes, mutateVideos, navigate, video?.id]);

    // const handleSuccess = (files) => {
    //     console.log('handleSuccess', files);
    //     const file = files[0];
    //     // updateVideo({ video_url: file.link });
    // }

    function deleteVideo() {
        // if (confirm('Are you sure you want to delete this video?')) {
        //     apiClient.delete(`/api/video/${video?.id}/`)
        //         .then((response) => {
        //             console.log('delete response', response);
        //             window.location = '/videos/';
        //         })
        //         .catch((error) => {
        //             console.log("error deleting video");
        //             console.dir(error);
        //         });
        // }
    }

    const handleUploadFromDropbox = (files) => {
        console.log('handleUploadFromDropbox', files);
        uploadFromDropbox(files, uploadId, video?.id)
            .then((response) => {
                console.log('response', response);
                // updateVideo({ video_url: response.url });
            })
            .catch((error) => {
                console.error("Error uploading from dropbox:", error);
                throw error;
            });
    }

    const editorButtons = useMemo(() => {
        return (
            <>
                <Button onClick={() => handleSave(true)} disabled={!isDirty} variant='outlined' size='small'
                        color='primary'>
                    Save
                </Button>

                <DropboxChooser
                    appKey="w7l7sbxwq4gyquq"
                    success={handleUploadFromDropbox}
                    multiselect={false}
                    // extensions={['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mpg', '.mpeg', '.m4v', '.webm', '.mkv']}
                    // linkType="preview"
                    linkText="direct"
                >
                    <Button className={'w-full'} variant='outlined' size='small' color='primary'>
                        Select a video from Dropbox
                    </Button>
                </DropboxChooser>

                <Button onClick={deleteVideo} variant='outlined' size='small' color='error'>
                    Delete
                </Button>

                {isDirty &&
                    <Typography variant="body2" color="error" className={'m-auto text-center'}>*** UNSAVED CHANGES
                        ***</Typography>}

                {video?.url_thumbnail && <img
                    src={video.url_thumbnail}
                    alt="video thumbnail"
                    className={'w-full'}
                />}

                {/*<VideoUploader video={video}/>*/}
            </>
        );
    }, [handleSave, isDirty, video]);

    // if (!video) return "no video";
    return (
        <PageContainer
            key={video?.id}
            loading={loading}
            maxWidth={'xl'}
            title={video?.title ?? video?.title ?? video?.id ?? ''}
            paths={[
                {title: 'Home', href: '/',},
                {title: 'Studio', href: '/studio/'},
                {title: 'Videos', href: '/studio/videos/'},
            ]}
        >
            <EditorContainer leftPanel={editorButtons}>
                {video && <TabsContainer tabs={tabs} defaultTab='general'/>}
            </EditorContainer>
{/*            <Modal
                // open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={true}
                isOpen={true}
                setIsOpen={() => {}}
            >
                hello
            </Modal>*/}
        </PageContainer>
    );
};


export default VideoEditor;
