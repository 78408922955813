import React, { useMemo } from 'react';
import InputText from "../../Quizzes/QuizEditor/FormControls";
import { useGetAPI } from "../../../context/api";
import { Dialog, DialogContent, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Add, Clear, Delete, Edit, Visibility, VisibilityOff } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useCreateLesson, useStudio } from "../../Context/StudioContext";
import { useDevTool } from "../../../context/DevTool";

const linkComponent = ({ className, ...props }) => {
    console.log('linkComponent', props);
    return (
        <div className={`select-none ${className} underline underline-offset-4 cursor-pointer`} {...props} >
            {props.value}
        </div>
    )
}

const LessonListDialog = ({ open, setOpen, lessons, lessonId, onSelectLesson }) => {

    const handleSelectLesson = (event, id) => {
        if (onSelectLesson)
            onSelectLesson(id);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle id="lessons-dialog">Lessons</DialogTitle>
            <DialogContent>
                <List>
                    {lessons?.map((lesson) => (
                        <ListItem disablePadding key={lesson.id}>
                            <ListItemButton
                                selected={lessonId === lesson.id}
                                onClick={(event) => handleSelectLesson(event, lesson.id)}
                            >
                                <ListItemText primary={lesson.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};


const LessonInputField = ({ lessonId, video, updateVideo }) => {
    const { lessons } = useStudio();
    const createLesson = useCreateLesson();
    const lesson = lessons?.find(lesson => lesson.id === lessonId);
    const navigate = useNavigate();
    const availableLessons = useMemo(() => lessons?.filter(lesson => ((lesson.id !== lessonId) && (!lesson.video))), [lessons, lessonId]);
    // useDevTool('LessonInputField', { lessonId, updateVideo, lessons, availableLessons });

    const onClickEdit = () => {
        console.log('onClickEdit', lessonId);
        if (lessonId)
            navigate(`/studio/lessons/${lessonId}`);
    };

    const onClickAdd = () => {
        // create a new lesson
        createLesson({ title: video?.title, video_id: video?.id })
            .then((lesson) => {
                navigate(`/studio/lesson/${lesson.id}`);
            })
            .catch((error) => {
                console.error('onClickAdd', error);

            });

    };

    const onClickRemove = () => {
        updateVideo({ lesson_id: null });
    };

    const endAdornment = (
        <InputAdornment position="end">

            <IconButton
                disabled={!lessonId}
                aria-label="edit lesson"
                onClick={onClickEdit}
                // onMouseDown={handleMouseDownPassword}
                // edge="end"
            >
                <Edit />
            </IconButton>

            <IconButton
                aria-label="add lesson"
                onClick={onClickAdd}
                // onMouseDown={handleMouseDownPassword}
                // edge="end"
            >
                <Add />
            </IconButton>

            <IconButton
                aria-label="remove lesson"
                onClick={onClickRemove}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                <Clear />
            </IconButton>
        </InputAdornment>
    );

    const [showLessonDialog, setShowLessonDialog] = React.useState(false);


    return (
        <>
            <InputText
                id='lesson'
                name='lesson'
                label="Lesson"
                value={lesson?.title ?? lessonId ?? ''}
                // value={lessonId}
                readOnly={true}
                // InputLabelProps={{ shrink: true }}
                endAdornment={endAdornment}
                // startAdornment={ <InputAdornment position="start">{lesson?.id}</InputAdornment>}
                InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    // inputComponent: linkComponent,
                }}
                inputComponent={linkComponent}
                inputProps={{
                    onClick: () => {
                        if (availableLessons.length === 0)
                            onClickAdd();
                        else
                            setShowLessonDialog(true);
                    },

                    // inputComponent: linkComponent,
                    // startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    url: `/studio/lessons/${lesson?.id}/`
                }}
            />

            <LessonListDialog
                open={showLessonDialog}
                setOpen={setShowLessonDialog}
                lessons={availableLessons}
                lessonId={lessonId}
                onSelectLesson={(id) => {
                    updateVideo({ lesson_id: id });
                    setShowLessonDialog(false);
                }}
            />
        </>
    );
};


export default LessonInputField;
