import React from 'react';
import { FormControl, FormLabel, InputLabel, MenuItem, Select } from "@mui/material";

const DropdownFilter = ({ id, label = null, items, onChange, initialSelected='', className='', showNone=true }) => {

    const [selected, setSelected] = React.useState(initialSelected);

    const handleChange = (event) => {
        const value = event.target.value;
        // onChange(e, playlist.id, 'lowest_grade_level', playlist.parent)
        console.log('selected', value);
        setSelected(value);
        if( onChange ) {
            onChange(value);
        }
    };

    return (
        <FormControl id={id}  sx={{ minWidth: 200 }} className={className}>

            {/*
            <FormLabel id="dropdown-filter-select-label" htmlFor="dropdown-filter">
                {label}
            </FormLabel>
            */}

            <InputLabel id={"dropdown-filter-select-label"}>
                {label}
            </InputLabel>

            <Select
                id="dropdown-filter"
                labelId="dropdown-filter-select-label"
                label={label}
                name='lowest_grade_level'
                value={selected}
                onChange={e => handleChange(e)}
                // sx={{ paddingX: 0 }}
            >
                {/*
                <MenuItem value={'none'}>None</MenuItem>
                */}
                {showNone && <MenuItem value={'-1'}>&nbsp;</MenuItem>}
                {items.map((item) => (
                    <MenuItem key={String(item[0])} value={String(item[0])}>
                        {item[1]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};


export default DropdownFilter;
