import {QUESTION_TYPE_MULTIPLE_SELECT} from "../QuestionSettingsDialog";
import {Card, CardContent, CardHeader, Checkbox, Radio, Typography, IconButton} from "@mui/material";
import RichText from "../../../../Components/RichText/RichText";
import {QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION} from "../constants";
import React, {useState} from "react";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import SettingsIcon from "@material-ui/icons/Settings";
import {QuestionItemChoice} from "./QuestionItemChoice";
import InPlaceEditor from "./InPlaceEditor";

export const QuestionItem = ({question, index, hideEdit}) => {
    const [isHovered, setIsHovered] = useState(false);

    let instructionText = null;
    if (question?.instruction_text) {
        instructionText = question?.instruction_text;
    } else if (question?.type === QUESTION_TYPE_MULTIPLE_SELECT) {
        instructionText = '(Select all options that apply.)';
    }

    return (
        <div
            className="bg-white shadow-md rounded-lg flex flex-col gap-2 p-4 pt-1 relative border-2 border-transparent hover:border-gray-600"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && (
                <div className="absolute top-2 right-2 w-8 h-8">
                    <IconButton
                        size="small"
                        onClick={() => console.log('Settings button clicked')}
                    >
                        <SettingsIcon/>
                    </IconButton>
                </div>
            )}

            <div className="flex items-center">
                <div className="flex items-center justify-center">
                    <span className="text-base font-medium">
                        {index + 1}.
                    </span>
                </div>

                <div className="ml-4 text-xl font-sans flex flex-col w-full">
                    <InPlaceEditor text={question?.question_text}>
                        {question.question_text ? <RichText
                            text={question?.question_text}
                            className={'text-lg'}
                        /> : <div className={'text-xs'}>'Click to edit...'</div>}
                    </InPlaceEditor>

                    {question?.image_url && (
                        <div className="my-2">
                            <img
                                src={question?.image_url}
                                className="my-1 max-w-[160px]"
                            />
                        </div>
                    )}

                    {instructionText && (
                        <div className="my-1">
                            <span className="text-lg font-sans">
                                {instructionText}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className="">
                {question?.choices?.map((choice, index) => {
                    return (
                        <QuestionItemChoice
                            key={choice.id}
                            id={choice.id}
                            index={index}
                            question={question}
                            choice={choice}
                        >
                            {/*<DragHandleIcon className="drag-handle cursor-move"/>*/}
                        </QuestionItemChoice>
                    );
                })}
            </div>
        </div>
    );
};
