import apiClient from "../../context/APIClient";


export const uploadFromDropbox = (files, uploadId, videoId) => {
    console.log('handleUploadFromDropbox', files);
    // const file = files[0];
    // updateVideo({ video_url: file.link });
    const payload = {
        files,
        upload_id: uploadId,
        video_id: videoId
    }
    return apiClient.post('/api/video_upload_dropbox/', payload)
        .then(res => {
            console.log('response', res.data);
            // console.log("mutating list");
            // mutate(({ data }) => {
            //     console.log('video files', data);
            //     return (
            //         [...data, {
            //             id: res.data.id,
            //             name: 'new',
            //             status: 'pending',
            //         }]
            //     )
            // });
            return res.data;
        })
        .catch(error => {
            console.error("Error uploading from dropbox:", error);
            throw error;
        });
}

