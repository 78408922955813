import React from 'react';
import tw, {css} from 'twin.macro';
import {useNavigate, useParams} from "react-router-dom";
import {useGetAPI} from "../../../context/api";
import ReactJson from "react-json-view";
import Grid from "@mui/material/Unstable_Grid2";
import {DataGrid} from "@mui/x-data-grid";
import {
    Button,
    CardActionArea,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    InputLabel, MenuItem,
    OutlinedInput,
    Paper, Select, Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import QuestionList from "./Tabs/QuestionList";
import {QUIZ_TYPE_CHOICES, QUIZ_TYPE_STANDARD} from "./constants";
import {useQuizEditor} from "./QuizEditorProvider";
import InputText, {InputSwitch} from "./FormControls";
import ConfirmDialog from "./ConfirmDialog";
import apiClient from "../../../context/APIClient";
import Loading from "../../../Components/Loading/Loading";
import Panel from "../../../Components/Dashboard/Panel";
import ShareDialog from "./Share/ShareDialog";
import {MUZOLOGY_API_URL} from "../../../config";
import QuizEditorMenu from "./QuestionList/QuizEditorMenu";
import Tab from "@mui/material/Tab";


const QuizEditor = ({quiz}) => {
    const {updateQuiz, quizDirty, saveQuiz, createNewQuestion} = useQuizEditor();
    // const { loading, error, data } = useGetAPI(`/api/fullquizzes/${quizId}/`);
    // console.log(loading, error, data);
    // if (loading)
    //     return <div>Loading...</div>;
    // if (error)
    //     return <div>Error: {error.message}</div>;
    // if (!data)
    //     return null;

    // return <div>Loading...</div>
    const handleChangeValue = (event) => {
        console.log('handleChangeValue', event.target.name, event.target.value);
        updateQuiz({[event.target.name]: event.target.value});
        // setQuiz({ ...quiz, title: event.target.value });
    }
    const handleChangeChecked = (event) => {
        let {name, checked} = event.target;
        console.log('handleChangeChecked', name, checked);
        updateQuiz({[name]: checked});
    }
    const [showShareDialog, setShowShareDialog] = React.useState(false);
    const handleConfirmShare = () => {
        setShowShareDialog(true);
    }
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const deleteQuiz = () => {
        setConfirmDeleteOpen(true);
    }
    const handleGenerateAudio = () => {
        if( quiz ) {
            setLoading(true);
            apiClient.post(`/api/quiz/${quiz.id}/generate_audio/`)
                .then((response) => {
                    console.log('generateAudio', response);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('generateAudio', error);
                    setLoading(false);
                });
        }
    }

    const navigate = useNavigate();
    const handleConfirmDeleteQuiz = () => {
        apiClient.delete(`/api/quiz/${quiz.id}/`)
            .then((response) => {
                console.log('deleteQuiz', response);
                navigate('/studio/quizzes/');
            })
            .catch((error) => {
                console.log('deleteQuiz', error);
            });
    }

    const [loading, setLoading] = React.useState(false);

    const handleSaveAndContinue = () => {
        saveQuiz();
    };

    const handleSave = () => {
        setLoading(true);
        saveQuiz()
            .then(() => {
                navigate('/studio/quizzes/');
            })
            .catch((error) => {
                console.log('handleSave', error);
                setLoading(false);
            })
            .finally(() => {
                console.log('saved')
                setLoading(false);
            });
    };
    const handleDuplicate = () => {
        setLoading(true);
        apiClient.post(`/api/quiz/${quiz.id}/duplicate/`)
            .then((response) => {
                console.log('duplicate', response);
                navigate(`/quiz/${response.data.id}/`);
            })
            .catch((error) => {
                console.log('duplicate', error);
                setLoading(false);
            })
            .finally(() => {
                console.log('saved')
                setLoading(false);
            });
    }

    const handleCreateQuestion = () => {
        console.log('create question');
        createNewQuestion(quiz);
    };

    const statusItems = [
        {id: 1, name: "Item 1", isValid: true},
        {id: 2, name: "Item 2", isValid: false},
        {id: 3, name: "Item 3", isValid: true},
        {id: 4, name: "Item 4", isValid: false},
    ];

    const menu = (
        <Stack spacing={2}>
            {/*
            <Button onClick={handleSave} disabled={!quizDirty} variant='outlined' size='small' color='success'>
                Save
            </Button>
            */}
            <Button onClick={handleSaveAndContinue} disabled={!quizDirty} variant='outlined' size='small'
                    color='primary'>
                Save
            </Button>

            <Button onClick={handleDuplicate} disabled={false} variant='outlined' size='small' color='primary'>
                Duplicate Quiz
            </Button>
            <Button onClick={handleConfirmShare} disabled={false} variant='outlined' size='small' color='primary'>
                Share Quiz
            </Button>

            <Button onClick={handleCreateQuestion} disabled={false} variant='outlined' size='small' color='primary'>
                Add Question
            </Button>

            {/*
                        <Button onClick={saveQuiz} disabled={!quizDirty} variant='outlined' size='small' color='primary'>
                            Save And Add Another
                        </Button>
                        */}
            {/* download docx link */}
            <a
                style={{width: '100%'}}
                href={`${MUZOLOGY_API_URL}api/quiz/${quiz.id}/export/docx/`}
                target='_blank'
                rel='noreferrer'
            >
                <Button variant='outlined' size='small' color='primary' style={{width: '100%'}}>
                    Download Docx
                </Button>
            </a>

            <Button disabled variant='outlined' size='small' color='secondary'>
                Test Quiz
            </Button>
            <Button disabled variant='outlined' size='small' color='secondary'>
                History
            </Button>
            <Button onClick={handleGenerateAudio} variant='outlined' size='small' color='secondary'>
                Generate Audio
            </Button>
            <Button onClick={deleteQuiz} variant='outlined' size='small' color='error'>
                Delete
            </Button>
        </Stack>
    )

    return (
        <form className={'w-full'}>
            <Loading loading={loading}/>

            <Grid container spacing={2}>

                <Grid item xs={12} md={8} lg={9} xl={10}>
                    <Stack spacing={2}>
                        <InputText
                            id='title'
                            label='Title'
                            name='title'
                            value={quiz?.title}
                            onChange={handleChangeValue}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="quiz-type-label">Type</InputLabel>
                            <Select
                                id="quiz-type"
                                labelId="quiz-type-label"
                                label="Type"
                                name='quiz_type'
                                value={quiz?.quiz_type}
                                onChange={handleChangeValue}
                            >
                                {QUIZ_TYPE_CHOICES.map((item) => (
                                    <MenuItem key={item[0]} value={String(item[0])}>{item[1]}</MenuItem>))}
                            </Select>
                        </FormControl>

                        {/*
                        <InputText
                            id='slug'
                            label='Slug'
                            name='slug'
                            value={quiz?.slug}
                            onChange={handleChangeValue}
                        />
                        */}

                        <InputText
                            id='description'
                            label='Description'
                            name='description'
                            value={quiz?.description}
                            onChange={handleChangeValue}
                            multiline
                            minRows={4}
                        />

                        <InputText
                            id='notes'
                            label='Notes'
                            name='notes'
                            value={quiz?.notes}
                            onChange={handleChangeValue}
                            multiline
                            minRows={4}
                        />

                        <InputSwitch
                            id='disable_random_question_order'
                            name='disable_random_question_order'
                            label='Disable random question order'
                            checked={quiz?.disable_random_question_order}
                            onChange={handleChangeChecked}
                        />
                        <InputSwitch
                            id='disable_random_question_order_level_1'
                            name='disable_random_question_order_level_1'
                            label='Disable random question order level 1'
                            checked={quiz?.disable_random_question_order_level_1}
                            onChange={handleChangeChecked}
                        />
                        <InputSwitch
                            id='disable_random_question_order_level_2'
                            name='disable_random_question_order_level_2'
                            label='Disable random question order level 2'
                            checked={quiz?.disable_random_question_order_level_2}
                            onChange={handleChangeChecked}
                        />
                        <InputSwitch
                            id='disable_random_question_order_level_3'
                            name='disable_random_question_order_level_3'
                            label='Disable random question order level 3'
                            checked={quiz?.disable_random_question_order_level_3}
                            onChange={handleChangeChecked}
                        />

                        {/*
                        <Panel title={'Publishing'}>
                            <Stack spacing={0}>
                                <table className="table-auto w-full">
                                    <tr>
                                        <td className="px-4 py-2 w-0 ">
                                            <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">
                                                YES
                                            </span>
                                        </td>
                                        <td className="px-4 py-2 ">
                                            Published
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2 ">
                                            <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">
                                                YES
                                            </span>
                                        </td>
                                        <td className="px-4 py-2 ">
                                            Valid
                                        </td>
                                    </tr>

                                </table>
                            </Stack>
                        </Panel>


*/}

                    </Stack>
                    {/*
                    <Grid container flex className={''}>
                        this is a button
                        <Button size='large' color={'primary'}>
                            save button
                        </Button>
                        this is not a button
                    </Grid>
                    */}
                    {/*                    <div className={'h-full'}>
                        <QuestionList />
                    </div>*/}
                </Grid>

                <Grid item xs={12} md={4} lg={3} xl={2}>
                    {menu}
                </Grid>

                <ConfirmDialog
                    title="Are you sure you want to delete this quiz?"
                    open={confirmDeleteOpen}
                    setOpen={setConfirmDeleteOpen}
                    onConfirm={handleConfirmDeleteQuiz}
                >
                    <p>Deleting this quiz will also delete all questions and answers.</p>
                </ConfirmDialog>
                <ShareDialog
                    open={showShareDialog}
                    setOpen={setShowShareDialog}
                    quiz={quiz}
                />
            </Grid>
        </form>
    );
};

export default QuizEditor;
