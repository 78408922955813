import React from 'react';
import { Grid, Paper } from "@mui/material";
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import FilesTree from "./FilesTree";
import useSWR from "swr";
import { useDevTool } from "../../../context/DevTool";

const FilesPanel = ({ video, uploadId, updateVideo, onSelectUpload }) => {
    const rootNodes = [
        {
            id: 'courses',
            itemType: 'Courses',
            title: 'Courses',
            children: []
        },
        {
            id: 'quizzes',
            itemType: 'Quizzes',
            title: 'Quizzes',
            children: []
        }
    ];
    const { data } = useSWR(`/api/video_upload/?video_id=${video.id}`);

    const files = data?.data?.map(upload => ({
            id: upload.id,
            name: upload.file_name,
            itemType: 'file',
            item: upload,
            fileType: upload.file_type,
            fileName: upload.file_name,
            fileSize: upload.file_size,
        })
    ) ?? [];

    useDevTool('FilesPanel', { files, data });

    return (
        <Grid container rowSpacing={1} columnSpacing={1} sx={{ minHeight: '200px' }}>
            <Grid item xs={12}>
                <Paper variant={'outlined'} sx={{ minHeight: '200px' }}>
                    <FilesTree files={files} />
                </Paper>
            </Grid>
        </Grid>

    );
};


export default FilesPanel;
