import React, {useMemo} from "react";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import {useSearchParams} from "react-router-dom";

export const TabPanel = (props) => {
    const {children, selected, value, index, ...other} = props;
    const isSelected = selected === value;
    return (
        <div
            role="tabpanel"
            hidden={!isSelected}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            className={'flex-grow h-full'}
            {...other}
        >
            {isSelected && (
                <Box sx={{p: 2, background: '', height: '100%', position: 'relative'}}>
                    {children}
                    {/*
                    <Typography>
                    </Typography>
                    <div className={'absolute inset-4 bg-red pb-10 flex items-stretch justify-stretch'}>
                        {children}
                    </div>
                    */}
                </Box>
            )}
        </div>
    );
}


const TabsContainer = ({tabs, defaultTab = null}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = useMemo(() => searchParams.get('tab') ?? defaultTab, [defaultTab, searchParams]);
    const handleTabChange = (event, value) => setSearchParams({tab: value});

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Video Tabs"
            >
                {tabs.map((t, index) => (
                    <Tab key={t.id} label={t.label} value={t.id} aria-controls={`tab-panel-${index}`}/>
                ))}
            </Tabs>

            {tabs.map((t, index) => (
                <TabPanel key={t.id} selected={tab} value={t.id} index={index}>
                    {t.component}
                </TabPanel>
            ))}
        </>
    );
};

export default TabsContainer;
