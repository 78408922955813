import React, { useMemo } from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Card, Link, Tab, Tabs, Typography } from "@mui/material";
import QuizEditor from "./QuizEditor";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetAPI } from "../../../context/api";
import { PageContainer } from "../../../Home/PageContainer";
import Box from "@mui/material/Box";
import QuestionList from "./Tabs/QuestionList";
import { useQuizEditor } from "./QuizEditorProvider";
import { QuestionsDropdown, QuizzesDropdown } from "./QuestionEditorToolbar";
import QuizDetails from "./QuizDetails";
import QuizWorksheet from "./Tabs/QuizWorksheet";
import QuizAnswerKeys from "./AnswerKey/QuizAnswerKeys.js";
import QuizImages from "./Tabs/QuizImages";
import QuizAudioTracks from "./Tabs/QuizAudioTracks";
import HealthCheck from "./HealthCheck";
import QuizPreviewsPanel from "./Tabs/QuizPreviewsPanel";
import QuizPublishTab from "./Tabs/QuizPublishTab";
import ChatPanel from "./QuestionEditor/Chat/ChatPanel";
import * as PropTypes from "prop-types";
import QuestionsList from "./QuestionsList";
import QuestionListEditor from "./QuestionListEditor";
import QuestionsEditor from "./QuestionList/QuestionsEditor";
import QuizSettingsPanel from "./Tabs/QuizSettingsPanel";
import QuizVersions from "./Tabs/QuizVersions";

function a11yProps(index) {
    return {
        'id': `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
        'className': 'h-full bg-green',
        'value': `${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={'flex flex-grow w-full bg-red flex-col h-full'}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, background: '', minHeight: '100%', position: 'relative' }}>
                    <div className={'inset-4- flex items-stretch justify-stretch'}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

function SettingsTab(props) {
    return null;
}

SettingsTab.propTypes = {};
const QuizEditorContainer = (props) => {
    const { quizId, quiz: data, loading, error } = useQuizEditor();

    // handle tabs
    // const [tab, setTab] = React.useState(0);
    // const handleTabChange = (event, newValue) => {
    //     setTab(newValue);
    // };
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log('searchParams', searchParams);
    const handleTabChange = (event, value) => {
        setSearchParams({ tab: value });
    };
    const tab = useMemo(() => {
        const tab = searchParams.get('tab');
        return tab ?? 'general';
    }, [searchParams]);


    if (loading)
        return <div>Loading...</div>;
    if (error)
        return <div>Error: {error.message}</div>;
    if (!data)
        return null;

    return (
        <PageContainer
            key={quizId}
            // title={data.title}
            title={<QuizzesDropdown />}
            paths={[
                { title: 'Home', href: '/',  },
                { title: 'Studio', href: '/studio',  },
                { title: 'Quizzes', href: '/studio/quizzes',  },
            ]}
            maxWidth={'2xl'}
        >
{/*
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={9}>
*/}

            <Card className={'flex-grow min-h-full'}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    aria-label="question editor tabs"
                >
                    <Tab label="General"  {...a11yProps('general')} />
                    <Tab label="Questions" {...a11yProps('questions')} />
                    <Tab label="Editor" {...a11yProps('questions_editor')} />
                    <Tab label="Answer Keys" {...a11yProps('answers')} />
                    <Tab label="Chat" {...a11yProps('chat')} />
                    {/*<Tab label="Worksheet" {...a11yProps('worksheet')} />*/}
                    {/*<Tab label="Images" {...a11yProps('images')} />*/}
                    {/*<Tab label="Audio" {...a11yProps('audio')} />*/}
                    {/*<Tab label="Details" {...a11yProps('details')} />*/}
                    <Tab label="Health Check" {...a11yProps('health-check')} />
                    {/*<Tab label="Previews" {...a11yProps('previews')} />*/}
                    {/*<Tab label="Publish" {...a11yProps('publish')} />*/}
                    {/*<Tab label="Versions" {...a11yProps('versions')} />*/}
                    <Tab label="Other" {...a11yProps('other')} />

                </Tabs>

                <TabPanel value={tab} index={'general'}>
                    <QuizEditor quiz={data} />
                </TabPanel>

                <TabPanel value={tab} index={'questions'}>
                    <QuestionList quiz={data} />
                </TabPanel>

                <TabPanel value={tab} index={'questions_editor'}>
                    <div className={'w-full'}>
                        <QuestionsEditor quiz={data}/>
                    </div>
                </TabPanel>

                <TabPanel value={tab} index={'answers'}>
                    <Container maxWidth={'lg'} sx={{ padding: '0', height: '100%', background: '' }}>
                        <QuizAnswerKeys quiz={data} />
                    </Container>
                </TabPanel>

                <TabPanel value={tab} index={'chat'}>
                    <ChatPanel quiz={data}/>
                </TabPanel>

                {/*
                <TabPanel value={tab} index={'worksheet'}>
                    <QuizWorksheet quiz={data} />
                </TabPanel>
                */}

                <TabPanel value={tab} index={'health-check'}>
                    <HealthCheck quiz={data} />
                </TabPanel>

                {/*
                <TabPanel value={tab} index={'previews'}>
                    <QuizPreviewsPanel quiz={data} />
                </TabPanel>
                */}

                <TabPanel value={tab} index={'other'}>
                    <QuizSettingsPanel quiz={data} />
                </TabPanel>

            </Card>
{/*
                </Grid>
            </Grid>
*/}
        </PageContainer>
    )

};


export default QuizEditorContainer;
