import React, {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import QuizImages from "./QuizImages";
import QuizAudioTracks from "./QuizAudioTracks";
import QuizDetails from "../QuizDetails";
import QuizPublishTab from "./QuizPublishTab";
import QuizVersions from "./QuizVersions";
import QuizWorksheet from "./QuizWorksheet";
import QuizPreviewsPanel from "./QuizPreviewsPanel";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        className: 'h-full bg-green-200 whitespace-nowrap',
        style: {whiteSpace: 'nowrap'}
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    if( value !== index ){
        return null;
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={'flex flex-grow w-full bg-red flex-col h-full'}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, background: '', minHeight: '100%', position: 'relative'}}>
                    <div className={'inset-4- flex items-stretch justify-stretch'}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

const QuizSettingsPanel = ({quiz}) => {
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        console.log(newValue);
        setTabValue(newValue);
    }

    return (
        <div className={'flex flex-row min-h-full flex-grow'}>

            <Tabs
                orientation="vertical"
                // variant="scrollable"
                value={tabValue}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={'w-[300px] flex flex-col'}
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    height: '100%',
                }}
            >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Worksheet" {...a11yProps(1)} />
                <Tab label="Images" {...a11yProps(2)} />
                <Tab label="Audio" {...a11yProps(3)} />
                <Tab label="Publish" {...a11yProps(4)} />
                {/*<Tab label="Previews" {...a11yProps(4)} />*/}
                <Tab label="Versions" {...a11yProps(5)} />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <QuizDetails quiz={quiz}/>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <QuizWorksheet quiz={quiz} />
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                <QuizImages quiz={quiz}/>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                <QuizAudioTracks quiz={quiz}/>
            </TabPanel>

            <TabPanel value={tabValue} index={4}>
                <QuizPublishTab quiz={quiz} />
            </TabPanel>

            <TabPanel value={tabValue} index={5}>
                <QuizVersions quiz={quiz} />
            </TabPanel>

            {/*
            <TabPanel value={tabValue} index={5}>
                <QuizPreviewsPanel quiz={quiz} />
            </TabPanel>
            */}





        </div>
    );
};

export default QuizSettingsPanel;
