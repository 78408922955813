import React, {useCallback, useEffect, useMemo} from 'react';
import QuestionEditor from "./QuestionEditor";
import Grid from "@mui/material/Unstable_Grid2";
import {useLocation, useNavigate, useParams, unstable_usePrompt as usePrompt} from "react-router-dom";

import {useGetAPI} from "../../../../context/api";
import {
    Tabs,
    Backdrop,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    IconButton,
    Link,
    Paper,
    Stack,
    Tab,
    Typography,
    TextField
} from "@mui/material";
import QuizEditor from "../QuizEditor";
import {PageContainer} from "../../../../Home/PageContainer";
import {QUIZ_LEVEL_CHOICES} from "../constants";
import {useQuizEditor} from "../QuizEditorProvider";
import QuestionEditorToolbar, {QuestionsDropdown} from "../QuestionEditorToolbar";
import QuestionPage from "../../../../Components/QuizView/QuestionPage";
import ConfirmDialog from "../ConfirmDialog";
import {InputSwitch} from "../FormControls";
import {MUZOLOGY_APP_URL} from "../../../../config";
import QuizPreviewClient from "../QuizPreviewClient";
import apiClient from "../../../../context/APIClient";
import {LoadingButton} from "@mui/lab";
import Loading from "../../../../Components/Loading/Loading";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import {
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    ArrowForwardRounded,
    ArrowRight,
    ArrowRightAlt,
    NavigateNext,
    SkipNext,
    SkipNextRounded,
    SkipPreviousRounded
} from "@material-ui/icons";
import {CaretRight} from "@remotion/cli/dist/editor/icons/caret";
import Box from "@mui/material/Box";
import QuestionNotes from "./QuestionNotes";
import ChatPanel from "./Chat/ChatPanel";
import QuizListDialog from "./QuizListDialog";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={`mt-4 flex flex-grow w-full flex-col h-full ${value === index ? '' : 'hidden'}`}
            {...other}
        >
            {value === index && (
                <div
                    className={'w-full min-h-full relative inset-4- -bg-yellow-400 flex items-stretch justify-stretch'}>
                    {children}
                </div>
            )}
        </div>
    );
}

const QuestionEditorContainer = () => {
    const navigate = useNavigate();

    const {
        quizId,
        questionId,
        quiz,
        isQuestionDirty,
        question,
        updateQuestion,
        updateChoice,
        newQuestion,
        saveQuestion,
        deleteQuestion,
        createNewQuestion
    } = useQuizEditor();

    // block navigation if question is dirty
    usePrompt({
        when: isQuestionDirty,
        message: "You have unsaved changes. Are you sure you want to leave?",
    });

    // ctrl+s to save
    useEffect(() => {
        const onKeyDown = (event) => {
            // console.log('keydown', event.key, event.ctrlKey, event.metaKey)
            if ((
                window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey
            ) && event.key === 's') {
                event.preventDefault();
                console.log('saving question');
                saveQuestion().then(() => {
                    console.log('saved question');
                });
            }
        };

        window.addEventListener("keydown", onKeyDown);
        return () => {
            // Cleanup on component unmount
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [saveQuestion]);


    // useEffect(() => {
    //     if (data) {
    //         const copy = JSON.parse(JSON.stringify(data));
    //         setQuiz(copy);
    //     }
    // }, [data]);

    // select the question
    // const question = useMemo(() => {
    //     if (quiz && questionId) {
    //         const question = quiz?.questions?.find((question) => question.id === questionId);
    //         console.log('selecting question', question);
    //         return question;
    //     }
    // }, [questionId, quiz]);

    // const updateQuestion = (question) => {
    //     console.log('updateQuestion', question);
    //     // setQuiz((quiz) => {
    //     //     const questions = quiz.questions.map((q) => {
    //     //         if (q.id === question.id) {
    //     //             return question;
    //     //         }
    //     //         return q;
    //     //     });
    //     //     return { ...quiz, questions };
    //     // });
    //     setDirty(true);
    // }

    // const saveUpdates = () => {
    //     // setQuiz({ ...data });
    //     // setQuiz(data);
    //     setDirty(false);
    // };
    //
    // const cancelUpdates = () => {
    //     // console.log('cancelUpdates', data);
    //     // const copy = JSON.parse(JSON.stringify(data));
    //     // setQuiz(copy);
    //     setDirty(false);
    // };
    //
    // const [showDropdown, setShowDropdown] = React.useState(false);
    // const QUIZ_LEVELS = ['Silver', 'Gold', 'Platinum'];
    // const dropdown = (
    //     <div className={'bg-red-200- cursor-pointer'} onClick={() => setShowDropdown(!showDropdown)}>
    //         {question?.id}
    //         <div className={showDropdown ? 'block' : 'hidden'}>
    //             <div className={' max-h-[400px] overflow-scroll cursor-pointer select-none bg-white shadow-md rounded-md p-2 absolute z-10'}>
    //                 {quiz?.questions?.map((question) => {
    //                     return (
    //                         <div key={question.id} className={'flex flex-row just`ify-start overflow-hidden max-w-[400px]'}>
    //                             <div className={'flex-shrink-0 w-[100px] bg-red-400-     mr-4'}>
    //                                 {QUIZ_LEVELS[question.level]}
    //                             </div>
    //                             <div className={'flex-shrink-0 bg-orange-500-'}>
    //                                 #{question.question_index}
    //                             </div>
    //                             <div className={'flex-shrink-1 overflow-hidden bg-green-400'}>
    //                                 {question.question_text}
    //                             </div>
    //                         </div>
    //                     );
    //                 })}
    //             </div>
    //         </div>
    //     </div>
    // )
    const handleCreateQuestion = useCallback( () => {
        console.log('create question', quiz);
        createNewQuestion(quiz);
    }, [createNewQuestion, quiz]);

    // current question index
    const questionIndex = useMemo(() => quiz?.questions?.findIndex((q) => q.id === question?.id), [question, quiz]);

    // navigate to the next question
    const navigateNextQuestion = useCallback(() => {
        // console.log('handleNext', questionIndex);
        // get the next question
        if (!quiz || questionIndex === quiz.questions.length - 1)
            return;
        const nextQuestion = quiz?.questions[questionIndex + 1];
        if (!nextQuestion)
            return;
        navigate(`/quiz/${quiz?.id}/question/${nextQuestion.id}`);
    }, [quiz, questionIndex, navigate]);

    const navigatePreviousQuestion = useCallback(() => {
        // console.log('handlePrevious', questionIndex);
        // get the previous question
        if (!quiz || questionIndex === 0)
            return;
        const nextQuestion = quiz?.questions[questionIndex - 1];
        if (!nextQuestion)
            return;
        navigate(`/quiz/${quiz.id}/question/${nextQuestion.id}`);
    }, [quiz, questionIndex, navigate]);

    // title control that contains the dropdown navigation
    const titleControl = (
        <div className={'flex flex-row justify-between items-center'}>
            <IconButton
                key={'previous'}
                onClick={navigatePreviousQuestion}
                disabled={questionIndex === 0}
            >
                <ArrowBackIosNewRounded/>
            </IconButton>
            <QuestionsDropdown/>
            <IconButton
                key={'next'}
                onClick={navigateNextQuestion}
                disabled={questionIndex === quiz?.questions?.length - 1}
            >
                <ArrowForwardIosRounded/>
            </IconButton>
        </div>
    )

    const title = newQuestion ? 'New Question' : titleControl;

    // const [open, setOpen] = React.useState(false);
    // const handleToggle = () => {
    //     setOpen(!open);
    // }
    // const handleClose = () => {
    //     setOpen(false);
    // }


    const [loadingAudio, setLoadingAudio] = React.useState(false);

    const generateAudio = useCallback(() => {
        if (!question) return;
        setLoadingAudio(true);
        apiClient.post(
            `/api/quiz/audio/?question_id=${question.id}`,
            {question_id: question.id}
        )
            .then((response) => {
                console.log(response);
                setLoadingAudio(false);
                // updateQuestion({ id: question.id, audio: response.data.audio_url });
                const items = response.data;
                if (items.length >= 1) {
                    console.log('updating question', items[0].audio_url);
                    // update the question audio
                    if (items[0].audio_url) {
                        updateQuestion({id: question.id, audio: items[0].audio_url}, false);
                    }
                    // now update each choice, skipping the first one
                    items.slice(1).forEach((item, index) => {
                        if (item.audio_url) {
                            console.log('updating choice', index, item.audio_url);
                            updateChoice({id: question.choices[index].id, audio: item.audio_url}, false);
                        }
                    });

                }
            })
            .catch((error) => {
                console.log(error);
                setLoadingAudio(false);
            });
    }, [question]);

    const handleSaveAndContinue = useCallback(() => {
        if (!isQuestionDirty) {
            console.log('not dirty, saving anyway');
            // return;
        }
        console.log('saving question');
        saveQuestion().then(() => {
            console.log('saved question');
        });
    }, [isQuestionDirty, saveQuestion]);

    const handleAddAnother = () => {
        handleCreateQuestion();
        // navigate(`/quiz/${quizId}/question/create`);
    }

    const handleSaveAndAddAnother = () => {
        saveQuestion()
            .then(() => {
                handleCreateQuestion();
                // navigate(`/quiz/${quizId}/question/create`);
            });
    };

    const handleSave = () => {
        saveQuestion().then(() => {
            console.log("* QUESTION SAVED");
            setTimeout( () => {
                navigate(`/studio/quiz/${quizId}/?tab=questions`);
            }, 10);
        });
    };

    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const handleDelete = () => {
        deleteQuestion().then(() => {
            navigate(`/studio/quiz/${quizId}`);
        });
    }
    const [showPreview, setShowPreview] = React.useState(false);

    const handleShowPreviewChange = (event) => {
        const {checked} = event.target;
        console.log(checked);
        setShowPreview(checked);
    }

    const [tab, setTab] = React.useState('editor');

    const handleTabChange = (event, newValue) => {
        console.log(newValue);
        setTab(newValue);
    }

    const [loadingDuplicateQuestion, setLoadingDuplicateQuestion] = React.useState(false);
    const handleDuplicateQuestion = () => {
        setLoadingDuplicateQuestion(true);
        apiClient.post(`/api/quiz/${quizId}/question/${questionId}/duplicate/`)
            .then((response) => {
                console.log(response);
                // got a new quiz back
                const newQuiz = response.data;
                newQuiz.questions?.forEach((question) => {
                    updateQuestion(question,false);
                });
                setLoadingDuplicateQuestion(false);
                navigate(`/studio/quiz/${quizId}/question/${question.id}`);
            })
            .catch((error) => {
                console.log(error);
                setLoadingDuplicateQuestion(false);
            });
    }
    const [showPickQuizDialog, setShowPickQuizDialog] = React.useState(false);
    const handleDuplicateAndMoveQuestion = () => {
        setShowPickQuizDialog(true);
    };
    const handleDuplicateAndMoveQuestionConfirmed = (quizId) => {
        setLoadingDuplicateQuestion(true);
        apiClient.post(`/api/quiz/${quizId}/question/${questionId}/duplicate/?dest_quiz_id=${quizId}`)
            .then((response) => {
                console.log(response);
                const question = response.data;
                setLoadingDuplicateQuestion(false);
                setShowPickQuizDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingDuplicateQuestion(false);
            });
    }


    return (
        <PageContainer
            // cols={12}
            maxWidth={false}
            title={title}
            // hideBreadcrumbs={true}
            paths={[{href: '/studio/quizzes/', title: 'Quizzes'},
                {href: `/studio/quiz/${quizId}/?tab=questions`, title: quiz?.title}]}
            // actions={[<QuestionsDropdown className={'max-w-[400px]'}/>]}
        >
            {/*
            <Grid item xs={12}>
                <QuestionEditorToolbar
                    disableSave={!questionDirty}
                    onSave={saveQuestion}
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                />
            </Grid>
            */}
            <Loading loading={loadingAudio}/>

            <Container maxWidth={false}>
                <Grid container spacing={2} className={'flex-grow'}>

                    <Grid item xs={12} md={8} lg={10}>
                        <Card>
                            {/*
                            <CardHeader>
                            </CardHeader>
                            */}
                            <CardContent>
                                <Stack>

                                    <Tabs
                                        value={tab}
                                        onChange={handleTabChange}
                                        variant={'scrollable'}
                                        aria-label="Quiz Editor Tabs"
                                        sx={{maxWidth: '100%'}}
                                    >
                                        <Tab label="Edit" value={'editor'}/>
                                        <Tab label="Preview" value={'preview'}/>
                                        <Tab label="Chat" value={'chat'}/>
                                        <Tab label="Notes" value={'notes'}/>
                                        <Tab label="Files" value={'files'}/>
                                    </Tabs
                                    >

                                    <TabPanel value={tab} index={'editor'}>
                                        <QuestionEditor
                                            quizId={quizId}
                                            quiz={quiz}
                                            questionId={questionId}
                                            question={question}
                                            updateQuestion={updateQuestion}
                                            updateChoice={updateChoice}
                                            dirty={isQuestionDirty}
                                        />
                                    </TabPanel>

                                    <TabPanel value={tab} index={'preview'}>
                                        <QuizPreviewClient
                                            show={tab === 'preview'}
                                            className={'w-full h-full flex flex-col  flex flex-col min-h-10 bg-red-200'}
                                            quiz={{...quiz, questions: [question]}}
                                            question={question}
                                            showLinks={false}
                                            // quizId={quiz?.id}
                                            // questionId={question?.id}
                                            // showPreview={showPreview}
                                        />
                                    </TabPanel>

                                    <TabPanel value={tab} index={'notes'}>
                                        <QuestionNotes question={question} updateQuestion={updateQuestion}/>
                                    </TabPanel>

                                    <TabPanel value={tab} index={'chat'}>
                                        <ChatPanel quiz={quiz} question={question}/>
                                    </TabPanel>

                                </Stack>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <Stack spacing={2}>
                            <div className={'flex flex-row justify-between items-center'}>
                                <IconButton
                                    key={'previous'}
                                    onClick={navigatePreviousQuestion}
                                    disabled={isQuestionDirty || questionIndex === 0}
                                >
                                    <ArrowBackIosNewRounded/>
                                </IconButton>
                                <div key={questionIndex} className={'w-full flex flex-col'}>
                                    <Typography variant='h6' component='h2'
                                                className={'text-center w-full -bg-red-500'}>
                                        <div
                                            className={'flex-row gap-1 font-[600] text-lg align-center justify-center'}>
                                            {QUIZ_LEVEL_CHOICES[question?.level]?.[1]}
                                            &nbsp;
                                            #{question?.question_index + 1}
                                        </div>
                                    </Typography>
                                    <div className={'text-xs text-center'}>
                                        Question {questionIndex + 1} of {quiz?.questions?.length}
                                    </div>
                                </div>
                                <IconButton
                                    key={'next'}
                                    onClick={navigateNextQuestion}
                                    disabled={isQuestionDirty || questionIndex === quiz?.questions?.length - 1}
                                >
                                    <ArrowForwardIosRounded/>
                                </IconButton>
                            </div>


                            <Button onClick={handleSaveAndContinue} disabled={!isQuestionDirty} variant='outlined' size='small'
                                    color='success'>
                                Save
                            </Button>
                            <Button onClick={handleSaveAndAddAnother} disabled={!isQuestionDirty} variant='outlined'
                                    size='small' color='primary'>
                                Save And Add Another
                            </Button>

                            <Button onClick={handleAddAnother} variant='outlined' size='small' color='primary'>
                                Add Another Question
                            </Button>

                            <LoadingButton loading={loadingDuplicateQuestion} onClick={handleDuplicateQuestion}
                                           variant='outlined' size='small' color='primary'>
                                Duplicate Question
                            </LoadingButton>
                            <LoadingButton loading={loadingDuplicateQuestion} onClick={handleDuplicateAndMoveQuestion}
                                           variant='outlined' size='small' color='primary'>
                                Copy Into Another Quiz
                            </LoadingButton>
                            <QuizListDialog
                                quizzes={null}
                                open={showPickQuizDialog}
                                setOpen={setShowPickQuizDialog}
                                onSelectQuiz={handleDuplicateAndMoveQuestionConfirmed}
                            />

                            <LoadingButton disabled={!question?.id} loading={loadingAudio} onClick={generateAudio}
                                           variant='outlined' size='small' color='primary'>
                                Generate TTS Audio
                            </LoadingButton>

                            <Button disabled variant='outlined' size='small' color='secondary'>
                                Test Quiz
                            </Button>
                            <Button disabled variant='outlined' size='small' color='secondary'>
                                History
                            </Button>
                            <Button onClick={() => setOpenConfirmDelete(true)} variant='outlined' size='small'
                                    color='error'>
                                Delete
                            </Button>

                            <ConfirmDialog
                                open={openConfirmDelete}
                                setOpen={setOpenConfirmDelete}
                                onConfirm={handleDelete}
                                title={'Delete Question'}
                                message={'Are you sure you want to delete this question?'}
                            />

                            {/*
                            <div tw={'p-10 m-10'}>
                                <QuestionPage question={question} className={'bg-red-100'} />
                            </div>
                            */}

                        </Stack>
                    </Grid>
                    {/*
                    <Grid item xs={12} md={5} lg={4} className={'-bg-gray-300'}>
                        <Card variant="outlined">
                            <CardHeader>
                                header
                            </CardHeader>
                            <CardContent>
                                content
                            </CardContent>
                            <CardActions>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>

                        <Grid container>
                            <Grid item xs={12}>
                                <Card>
                                    card
                                </Card>
                                <Typography variant={'h6'}>Preview</Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={'bg-red-300'}>
                            <Typography variant={'h6'}>Preview</Typography>
                            <Grid item xs={12} className={'bg-blue-300'}>
                                <Typography variant={'h6'}>Preview</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
*/}

                    {/*
                    {showSidebar && <Grid item xs={12} md={5} lg={4} className={'bg-gray-300'}>
                        preview
                    </Grid>}
                    */}
                </Grid>
            </Container>

            <div className={'hidden'}>
                <Container maxWidth={false}>
                    <Grid container>
                        {/*<Grid container spacing={2} justifyContent={'stretch'} alignItems={'stretch'}>*/}
                        <Grid item sm={12}>
                            <InputSwitch
                                id='show-preview'
                                label='Preview'
                                name='show_preview'
                                value={showPreview}
                                onChange={handleShowPreviewChange}
                            />

                            {showPreview && <QuizPreviewClient
                                quiz={{...quiz, questions: [question]}}
                                question={question}
                                // quizId={quiz?.id}
                                // questionId={question?.id}
                                // showPreview={showPreview}
                            />}

                            {/*{showPreview && <QuestionPage question={question} />}*/}
                            {/*
                        {false && <div className={' gap-2 flex flex-row min-h-[800px] w-full  items-stretch'}>
                            <div className={'flex flex-row items-center bg-blue-700 flex-grow h-[2048px]'}>
                                <iframe title={'Muzology Quiz Preview'} src={`${MUZOLOGY_APP_URL}preview/${quizId}/question/${questionId}/?question=${questionEncoded}`} width={'100%'}
                                        height={'100%'} />
                                <Switch checked={showPreview} onChange={handleShowPreviewChange} />
                            </div>
                            <div className={'flex flex-row items-center bg-blue-700'}>
                                <iframe title={'Muzology Quiz Preview - Mobile'} src={`${MUZOLOGY_APP_URL}preview/${quizId}/question/${questionId}/`} width={'100%'} height={'100%'} />
                                <Switch checked={showPreview} onChange={handleShowPreviewChange} />
                            </div>
                        </div>}
                        */}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </PageContainer>
    )
};


export default QuestionEditorContainer;
