import {QUESTION_TYPE_MULTIPLE_SELECT} from "../QuestionSettingsDialog";
import {Card, CardContent, CardHeader, Checkbox, Radio, Typography} from "@mui/material";
import RichText from "../../../../Components/RichText/RichText";
import {QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION} from "../constants";
import React from "react";

export const AnswerKeyQuestion = ({question, index, hideEdit}) => {

    let instructionText = null;
    if (question?.instruction_text) {
        // instructionText = `(${question?.instruction_text})`;
        instructionText = question?.instruction_text;
    } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
        instructionText = '(Select all options that apply.)';
    }


    return (

        <Card variant={'blank'} sx={{}}>

            <CardHeader
                // avatar={<Avatar variant={'rounded'} sx={{ bgcolor: getLevelBackground(question.level) }}>{index + 1}</Avatar>}
                avatar={
                    <Typography variant={'h4'}>
                        {index + 1}.
                    </Typography>
                }
                title={
                    <Typography variant={'h4'} sx={{fontSize: 26, fontFamily: 'Helvetica'}}>
                        <RichText text={question.question_text}/>

                        {question.image_url && <div className={''}>
                            <img
                                src={question.image_url}
                                className={'my-1 max-w-[160px]'}
                            />
                        </div>}

                        {instructionText && <div className={'my-1'}>
                            <Typography
                                variant={'p'}
                                sx={{fontSize: 20, fontFamily: 'Helvetica'}}
                            >
                                {instructionText}
                            </Typography>
                        </div>}

                    </Typography>
                }
            />

            <CardContent>
                <>
                    {question.choices.map((choice, index) => {
                        let decorator = null;
                        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
                            decorator = (
                                <Radio checked={choice.correct}/>
                            )
                        } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
                            decorator = (
                                <Checkbox checked={choice.correct}/>
                            )
                        }
                        return (
                            <div key={choice.id} className={'flex flex-row items-center gap-4'}>
                                {/*
                            <Avatar
                                sx={{
                                    bgColor: 'transparent',
                                    // bgcolor: 'red'
                                }}
                            >
                                {String.fromCharCode(65 + index)}
                            </Avatar>
                            */}
                                {decorator}

                                <Typography
                                    variant='h6'
                                    sx={{
                                        // bgColor: 'transparent',
                                        // bgcolor: 'red'
                                    }}
                                >
                                    {String.fromCharCode(65 + index)})
                                </Typography>

                                {choice.content_image ?
                                    <img
                                        src={choice.choice_text}
                                        className={'max-h-[160px] max-w-[200px] aspect-square'}
                                    />
                                    :
                                    <Typography variant={'body1'} sx={{fontSize: 20, fontFamily: 'Helvetica'}}>
                                        <RichText text={choice.choice_text}/>
                                    </Typography>}
                            </div>
                        );
                    })}
                </>
                {/*
                <div key={question.id}>
                    <div className={'font-bold text-gray-600'}>{index + 1}. {question.question_text}</div>
                    <div className={'font-bold text-gray-600'}>Answer: {question.choices[question.answer].choice}</div>
                </div>
                */}
            </CardContent>

        </Card>
    );
};
