import React, {useEffect, useMemo} from 'react';
import {Outlet, useParams} from "react-router-dom";
import CourseEditor from "../Courses/CourseEditor";
import {useGetAPI} from "../../context/api";
import {useDevTool} from "../../context/DevTool";
import {useStudio} from "../Context/StudioContext";
import DropdownFilter from "../../Admin/Accounts/DropdownFilter";

const itemTypeDefs = [
    CourseEditor,
    {
        slug: 'lessons',
        key: 'lessons',
        name: 'Lesson',
        namePlural: 'Lessons',
        columns: [
            {
                name: 'title',
                label: 'Title',
                flex: 1,
            },
        ]
    }
]

const Context = React.createContext(null);

const StudioContainer = (props) => {
    // find the item typedef
    const {itemType, itemId} = useParams();
    const [columns, setColumns] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [item, setItem] = React.useState(null);

    // get the item type definition
    const itemTypeDef = useMemo(() => {
        // console.log(itemTypeDefs, itemType);
        return itemTypeDefs.find((typeDef) => typeDef.slug === itemType)
    }, [itemType]);

    // get the studio data
    const studio = useStudio();

    // fetch the volumes
    const {data: volumes} = useGetAPI(`/api/volumes/`);

    const [selectedVolume, setSelectedVolume] = React.useState('df69d326-5ced-419e-8cfd-a7d5b7732ec9');

    const volumeItems = useMemo(() => {
        return volumes?.map(volume => {
            return [volume.id, volume.title];
        }) ?? [];
    }, [volumes]);
    // get the data for the item type
    const data = useMemo(() => {
        if (studio && itemTypeDef && itemTypeDef.key) {
            return studio[itemTypeDef.key];
        }
        return null;
    }, [studio, itemTypeDef]);

    // const { loading, error, data } = useGetAPI(itemTypeDef?.api ? itemTypeDef?.api : `/api/${itemType}/`);
    // const { data: lessons } = useGetAPI(`/api/lessons/`);
    // const { data: videos } = useGetAPI(`/api/video/`);
    // const { data: quizzes } = useGetAPI(`/api/quizzes/`);
    const {lessons, videos, quizzes} = studio;

    // const item = useMemo(() => {
    //     console.log('item', itemId, items);
    //     return items.find((item) => item.id === itemId);
    // });

    // store the items and columns
    useEffect(() => {
        // console.log('itemTypeDef', itemTypeDef);

        if (!data) {
            setItems([]);
            setColumns([]);
            return;
        }

        // save the items
        setItems(data);
        // console.log('columns', itemTypeDef, itemTypeDef?.columns);
        if (itemTypeDef?.columns) {
            setColumns(itemTypeDef.columns);
        } else {
            // if we don't have any columns defined, gather a list of columns from the items
            const cols = [];
            if (data) {
                for (let item of data) {
                    for (let key in item) {
                        if (!cols.includes(key)) {
                            cols.push(key);
                        }
                    }
                }
            }
            setColumns(cols);
        }

    }, [data, itemTypeDef]);

    // load the item when the itemId changes
    useEffect(() => {
        if (itemId && items) {
            // item is a course
            const course = items.find((item) => item.id === itemId);
            if (course) {
                // add the lessons and quizzes to the course
                // course.lessons is a list of lesson ids, so we need to find the lesson objects
                if (lessons && !course.lesson_objects) {
                    course.lesson_objects = course.lessons?.map((lessonId) => lessons.find((lesson) => lesson.id === lessonId));
                    // console.log('lessons found:', course.lesson_objects);
                }

                if (videos) {
                    course.video_objects = videos;
                    // console.log('finding video', course.videos, 'in', videos);
                    // course.video_objects = course.videos?.map((videoId) => videos.find((video) => video.id === videoId));
                    // console.log('videos found:', course.video_objects);
                }

                // course.lessons = lessons.filter((lesson) => lesson.courseId === course.id);
                // course.quizzes = quizzes.filter((quiz) => quiz.courseId === course.id);
                // course.videos = videos.filter((video) => video.courseId === course.id);

                setItem(course);
            }

            // setItem(items.find((item) => item.id === itemId));
        }
    }, [itemId, items, lessons, videos]);

    const value = {
        volumes,
        selectedVolume,
        itemType,
        itemTypeDef,
        items: data,
        columns,
        itemId,
        item
    };

    useDevTool('Studio', {value, studio, volumes, volumeItems})

    const handleChangeVolume = (value) => {
        console.log('onChangeVolume', value);
    };

    return (
        <Context.Provider value={value}>
            {/*
                {volumeItems.length > 0 && <StudioVolumeSelectorContainer
                selectedVolume={selectedVolume}
                handleChangeVolume={handleChangeVolume}
                volumeItems={volumeItems}
            />}
            */}
            <Outlet/>
        </Context.Provider>
    );
};

const StudioVolumeSelectorContainer = ({selectedVolume, handleChangeVolume, volumeItems}) => {
    console.log('StudioVolumeSelectorContainer', selectedVolume, volumeItems);

    return (
        <div className={'w-full h-full flex flex-grow bg-[transparent] flex-col'}>

            <div className='flex flex-row w-full bg-[transparent] py-1 px-10 '>
                <div className={'flex flex-row items-center gap-4'}>
                    <DropdownFilter
                        label={'Course'}
                        items={volumeItems}
                        onChange={handleChangeVolume}
                        initialSelected={selectedVolume}
                    />
                </div>
            </div>

            <div className={'w-full bg-[#fff] flex-grow relative'}>
                <Outlet/>
            </div>

        </div>
    );
};

export function useItemEditor() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(`useItemEditor must be used within a StudioContainer`)
    }
    return context
}


export default StudioContainer;
