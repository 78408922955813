import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputText, { InputSwitch } from "../../FormControls";
import { FormControl, FormGroup, FormLabel, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ConfirmDialog from "../../ConfirmDialog";
import { useDevTool } from "../../../../../context/DevTool";
import apiClient from "../../../../../context/APIClient";
import Loading from "../../../../../Components/Loading/Loading";
import RichText from "../../../../../Components/RichText/RichText";
import Panel from "../../../../../Components/Dashboard/Panel";
import useSWR from "swr";

const ChoiceSettingsDialog = (props) => {
    const { choice, updateChoice, open, setOpen, onConfirm, onDelete } = props;
    useDevTool('choice', choice);

    const handleChangeValue = (event) => {
        let { name, value } = event.target;
        console.log('handleChangeValue', name, value);
        updateChoice?.({ id: choice.id, [name]: value });
    }

    const handleChangeChecked = (event) => {
        let { name, checked } = event.target;
        console.log('handleChangeChecked', name, checked);
        updateChoice?.({ id: choice.id, [name]: checked });
    }
    // const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [math, setMath] = React.useState(null);
    const handleConvertToMath = () => {
        // call the server
        const prompt = `You are a converter from textual / english representations of math to katex/latex representation.\n` +
            `You will be given text representation of a math equation and you will convert it to katex/latex.\n` +
            `Do not output anything but the katex/latex math. Do not wrap the answer in $'s.\n` +
            'Text should be wrapped in \\text{...}.\n' +
            `For example, if the string is: "1/5" then you would output: "\frac{1}{5}" \n\n` +
            `Input: ${choice.choice_text}\n` +
            `Output: `;
        setLoading(true);
        apiClient.post('/chatgpt/', { 'prompt': prompt })
            .then(response => {
                const { data } = response;
                const output = data.choices[0].message?.content;
                console.log("handleConvertToMath", output);
                setMath(output);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    const [tts, setTTS] = React.useState(null);
    const handleConvertToTTS = () => {
        // call the server
        const prompt = `You are a converter from rich text that may include representations of math in katex/latex to an english description.\n` +
            `You will be given text, any representation of math equation will be wrapped in <math>...</math> tags \n` +
            'The text you generate will be used for TTS (text to speech) and should be in english.\n' +
            `Do not output anything but the english translation of the string sutable for TTS.\n` +
            'If the text is a mathmatical fraction, use terms such as "two thirds" instead of "two divided by three" or "two over three" ' +
            'Output one answer and stop.\n' +
            `What is the english translation of "${choice.choice_text}":\n`;
        // `For example, if the string is: "What is <math>\frac{1}{5}</math>?" then you would output: What is one fifth?\n` +
        setLoading(true);
        apiClient.post('/chatgpt/', { 'prompt': prompt })
            .then(response => {
                const { data } = response;
                const output = data.choices[0].message?.content;
                setTTS(output);
                setLoading(false);
                updateChoice?.({ id: choice.id, audio_tts_override: output });
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    const handleReplaceWithMath = () => {
        updateChoice?.({ id: choice.id, choice_text: `<math>${math}</math>` });
    };
    const handleReplaceWithTTS = () => {
        updateChoice?.({ id: choice.id, audio_tts_override: tts });
    };

    const { data: audioConfig } = useSWR('/api/quiz/audio/?choice_id=' + choice?.id);
    // console.log('audioConfig', audioConfig?.data);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="settings-dialog"
        >
            <Loading loading={loading} />
            <DialogTitle id="settings-dialog">Choice Settings</DialogTitle>
            <DialogContent>
                <form className={'w-full'}>
                    <Stack spacing={2}>
                        <FormLabel component="legend">Contents</FormLabel>
                        <Stack spacing={2}>
                            <FormGroup>
                                <InputText
                                    id='choice_text'
                                    name='choice_text'
                                    value={choice.choice_text}
                                    label='Text'
                                    onChange={handleChangeValue}
                                />
                            </FormGroup>

                            <Panel title={'GPT Generator'}>
                                <Stack spacing={2}>
                                    <Stack spacing={2} direction={'row'}>
                                        <div>
                                            <Button variant="outlined" onClick={handleConvertToMath} color="warning">
                                                Convert to math
                                            </Button>
                                        </div>
                                        {math && <div className={'p-1 px-4 border-[1px] border-solid border-gray-300'}>
                                            <RichText text={`<math>${math}</math>`} />
                                        </div>}
                                    </Stack>
                                    {math && <Stack spacing={2} direction={'row'}>
                                        <div>
                                            <Button variant="outlined" onClick={handleReplaceWithMath} color="warning">
                                                Replace
                                            </Button>
                                        </div>
                                        <div className={'p-1 px-4 border-[1px] border-solid border-gray-300'}>
                                            <div>{math}</div>
                                        </div>
                                    </Stack>}
                                </Stack>
                            </Panel>
                            <FormGroup>
                                <InputSwitch
                                    id='content_image'
                                    label='Image'
                                    name='content_image'
                                    value={choice.content_image}
                                    onChange={handleChangeChecked}
                                />

                                <InputSwitch
                                    id='correct'
                                    label='Correct'
                                    name='correct'
                                    value={choice.correct}
                                    onChange={handleChangeChecked}
                                />
                                <InputSwitch
                                    id='last_position'
                                    label='Last Position'
                                    name='last_position'
                                    value={choice.last_position}
                                    onChange={handleChangeChecked}
                                />
                            </FormGroup>

                            <InputText
                                id='choice_index'
                                name='choice_index'
                                value={choice.choice_index}
                                label='Index'
                                onChange={handleChangeValue}
                            />

                        </Stack>

                        <FormLabel component="legend">Text To Speech</FormLabel>

                        <FormControl component="fieldset" variant="filled">

                        </FormControl>

                        <Stack spacing={2}>
                            <InputText
                                id='audio'
                                label='Audio URL'
                                name='audio'
                                value={choice.audio}
                                onChange={handleChangeValue}
                            />

                            <InputText
                                id='audio_tts_override'
                                label='TTS Override'
                                name='audio_tts_override'
                                value={choice.audio_tts_override ?? ""}
                                onChange={handleChangeValue}
                            />
                            <Panel title={'GPT Generator'}>
                                <Stack spacing={2}>
                                    <Stack spacing={2} direction={'row'}>
                                        <div>
                                            <Button variant="outlined" onClick={handleConvertToTTS} color="warning">
                                                Convert to TTS
                                            </Button>
                                        </div>
                                        {tts && <div className={'p-1 px-4 border-[1px] border-solid border-gray-300'}>
                                            <div>{tts}</div>
                                        </div>}
                                    </Stack>

                                    {tts && <Stack spacing={2} direction={'row'}>
                                        <div>
                                            <Button variant="outlined" onClick={handleReplaceWithTTS} color="warning">
                                                Replace
                                            </Button>
                                        </div>
                                        <div className={'p-1 px-4 border-[1px] border-solid border-gray-300'}>
                                            <div>{tts}</div>
                                        </div>
                                    </Stack>}
                                </Stack>
                            </Panel>

                        </Stack>
                        {/*
                        <Button
                            variant="outlined"
                            onClick={() => setConfirmDeleteOpen(true)}
                            color="warning"
                        >
                            Delete
                        </Button>
                        */}
                    </Stack>


                </form>
                {audioConfig?.data && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant={'h6'}>Audio</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Audio Generated</TableCell>
                            <TableCell>{audioConfig?.data.audio_generated_date ? 'YES' : 'NO'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date Generated</TableCell>
                            <TableCell>{audioConfig?.data.audio_generated_date}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>{audioConfig?.data.audio_url}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Source Question Text</TableCell>
                            <TableCell>{audioConfig?.data.audio_source_text}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Source TTS Override</TableCell>
                            <TableCell>{audioConfig?.data.audio_tts_override}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Text Requested</TableCell>
                            <TableCell>{audioConfig?.data.audio_text}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                        if (onConfirm) {
                            onConfirm();
                        }
                    }}
                    color="success"
                >
                    Save
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default ChoiceSettingsDialog;
