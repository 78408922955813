import React, {useState} from 'react';
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {QuestionItem} from "./QuestionItem";
import {QuestionChoice} from "../QuestionEditor/ChoiceEditor/QuestionChoice";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import QuizEditorMenu, {QuizActions} from "./QuizEditorMenu";
import {useQuizEditor} from "../QuizEditorProvider";
import {QUIZ_BACKGROUNDS} from "../constants";
import {Button, Typography} from "@mui/material";

// Single List Component
const QuestionListEditor = ({items, setItems}) => {
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [movedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, movedItem);
        setItems(newItems);
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="reorderable-list">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            userSelect: 'none',
                                            padding: 16,
                                            marginBottom: 8,
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: 4,
                                            ...provided.draggableProps.style,
                                        }}
                                    >
                                        <QuestionItem
                                            key={item.id}
                                            question={item}
                                            index={item.question_index}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

// import React from 'react';
// import { Droppable, Draggable } from 'react-beautiful-dnd';
// import QuestionItem from './QuestionItem';

const DroppableContainer = (
    {
        droppableId,
        listB,
        listTitle = null,
        backgroundColor = null,
        onAddQuestion = null
    }) => {
    return (
        <Droppable droppableId={droppableId}>
            {(provided) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={'w-full p-4 border rounded-lg'}
                    style={{
                        backgroundColor: backgroundColor ?? '#f0f0f0',
                    }}

                    // style={{
                    //     padding: 16,
                    //     minHeight: 200,
                    //     backgroundColor: '#e0e0e0',
                    //     borderRadius: 4,
                    // }}
                >
                    {listTitle && <Typography variant={'h5'} marginBottom={1}>{listTitle}</Typography>}

                    {listB?.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={'user-select-none mb-2'}
                                    style={{
                                        // userSelect: 'none',
                                        // padding: 16,
                                        // marginBottom: 8,
                                        // backgroundColor: '#f0f0f0',
                                        // borderRadius: 4,
                                        ...provided.draggableProps.style,
                                    }}
                                >
                                    {item && <QuestionItem
                                        key={item?.id}
                                        question={item}
                                        index={index}
                                        // index={item?.question_index}
                                    />}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {onAddQuestion && <div className={'w-full bg-white rounded'}>
                        <Button fullWidth onClick={() => onAddQuestion(droppableId)}>
                            Add Question
                        </Button>
                    </div>}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};


// Dual List Component
const DraggableDualList = (
    {
        showLevel1=true,
        showLevel2=true,
        showLevel3=true,
        listLevel1,
        setListLevel1,
        listLevel2,
        setListLevel2,
        listLevel3,
        setListLevel3,
        showQuestionBank = false,
        listBank = null,
        setListBank = null,
    }) => {

    const getSourceList = (droppableId) => {
        switch (droppableId) {
            case 'listLevel1':
                return listLevel1;
            case 'listLevel2':
                return listLevel2;
            case 'listLevel3':
                return listLevel3;
            case 'listBank':
                return listBank;
            default:
                return [];
        }
    };

    const getSourceSetter = (droppableId) => {
        switch (droppableId) {
            case 'listLevel1':
                return setListLevel1;
            case 'listLevel2':
                return setListLevel2;
            case 'listLevel3':
                return setListLevel3;
            case 'listBank':
                return setListBank;
            default:
                return () => {
                };
        }
    };

    const getSourceLevel = (droppableId) => {
        switch (droppableId) {
            case 'listLevel1':
                return 0;
            case 'listLevel2':
                return 1;
            case 'listLevel3':
                return 2;
            case 'listBank':
                return 4;
            default:
                return 0;
        }
    }


    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        let sourceList, setSourceList, destinationList, setDestinationList, level;

        switch (result.source.droppableId) {
            case 'listLevel1':
                sourceList = listLevel1;
                setSourceList = setListLevel1;
                break;
            case 'listLevel2':
                sourceList = listLevel2;
                setSourceList = setListLevel2;
                break;
            case 'listLevel3':
                sourceList = listLevel3;
                setSourceList = setListLevel3;
                break;
            case 'listBank':
                sourceList = listBank;
                setSourceList = setListBank;
                break;
            default:
                return;
        }

        switch (result.destination.droppableId) {
            case 'listLevel1':
                level = 0;
                destinationList = listLevel1;
                setDestinationList = setListLevel1;
                break;
            case 'listLevel2':
                level = 1;
                destinationList = listLevel2;
                setDestinationList = setListLevel2;
                break;
            case 'listLevel3':
                level = 2;
                destinationList = listLevel3;
                setDestinationList = setListLevel3;
                break;
            case 'listBank':
                level = 4;
                destinationList = listBank;
                setDestinationList = setListBank;
                break;
            default:
                return;
        }

        // remove the item from the source list
        const [movedItem] = sourceList.splice(result.source.index, 1);

        // update the level of the moved item
        movedItem.level = level;

        // add the item to the destination list
        if (result.source.droppableId === result.destination.droppableId) {
            // drop in the same list
            sourceList.splice(result.destination.index, 0, movedItem);
            setSourceList([...sourceList]);
        } else {
            // drop in a different list
            destinationList.splice(result.destination.index, 0, movedItem);

            setSourceList([...sourceList]);
            setDestinationList([...destinationList]);
        }

        // renumber all items in the 3 level source lists globally from 0 to n
        const allItems = [...listLevel1, ...listLevel2, ...listLevel3];
        allItems.forEach((item, index) => {
            item.question_index = index;
        });

        // const sourceList = result.source.droppableId === 'listA' ? listA : listB;
        // const destinationList = result.destination.droppableId === 'listA' ? listA : listB;
        // const setSourceList = result.source.droppableId === 'listA' ? setListA : setListB;
        // const setDestinationList = result.destination.droppableId === 'listA' ? setListA : setListB;
        //
        // const [movedItem] = sourceList.splice(result.source.index, 1);
        //
        // if (result.source.droppableId === result.destination.droppableId) {
        //     sourceList.splice(result.destination.index, 0, movedItem);
        //     setSourceList([...sourceList]);
        // } else {
        //     destinationList.splice(result.destination.index, 0, movedItem);
        //     setSourceList([...sourceList]);
        //     setDestinationList([...destinationList]);
        // }
    };

    const handleOnAddQuestion = (droppableId) => {
        console.log("handleOnAddQuestion", droppableId);
        // createNewQuestion();
        const level = getSourceLevel(droppableId);
        getSourceSetter(droppableId)(current => {
            return [
                ...current,
                {
                    id: `question-${current.length}`,
                    question_text: 'New Question',
                    level: level,
                    question_index: current.length,
                }
            ]
        });
    };

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <div
                className={'w-full flex flex-row gap-4 bg-green-300- user-select-none'}
                // style={{width: '100%', display: 'flex', gap: '20px'}}
            >
                <div
                    className={'w-full flex-grow flex flex-row gap-4'}
                    // style={{width: '100%', display: 'flex', gap: '20px'}}
                >        showLevel1=true,
                    showLevel2=true,
                    showLevel3=true,

                    {showLevel1 && <DroppableContainer
                        droppableId="listLevel1"
                        listB={listLevel1}
                        listTitle={"Silver Level"}
                        onAddQuestion={handleOnAddQuestion}
                        backgroundColor={QUIZ_BACKGROUNDS[0]}
                    />}

                    {showLevel2 && <DroppableContainer
                        droppableId="listLevel2"
                        listB={listLevel2}
                        listTitle={"Gold Level"}
                        onAddQuestion={handleOnAddQuestion}
                        backgroundColor={QUIZ_BACKGROUNDS[1]}
                    />}

                    {showLevel3 && <DroppableContainer
                        droppableId="listLevel3"
                        listB={listLevel3}
                        listTitle={"Platinum"}
                        onAddQuestion={handleOnAddQuestion}
                        backgroundColor={QUIZ_BACKGROUNDS[2]}
                    />}

                </div>
                {showQuestionBank && <div
                    className={'w-1/4 flex flex-row gap-4 bg-green-300'}
                    // style={{width: '100%', display: 'flex', gap: '20px'}}
                >
                    <DroppableContainer droppableId="listBank" listB={listBank} listTitle={"QuestionBank"}/>
                </div>}
            </div>
        </DragDropContext>
    );
};

export default function QuestionsEditor({quiz}) {
    const {updateQuiz, quizDirty, saveQuiz, createNewQuestion} = useQuizEditor();

    console.log("QuestionsEditor", quiz);
    // const [items, setItems] = useState([
    //     { id: 'item-1', content: 'Item 1 - This is a variable height item' },
    //     { id: 'item-2', content: 'Item 2' },
    //     { id: 'item-3', content: 'Item 3 with more content that makes it taller than others' },
    // ]);
    const [items1, setItems1] = useState(quiz?.questions?.filter(question => question.level === 0) ?? []);
    const [items2, setItems2] = useState(quiz?.questions?.filter(question => question.level === 1) ?? []);
    const [items3, setItems3] = useState(quiz?.questions?.filter(question => question.level === 2) ?? []);
    const [bank, setBank] = useState([]);
    const [showQuestionBank, setShowQuestionBank] = useState(true);

    const handleSaveAndContinue = () => {
        saveQuiz();
    }

    const menuItems = [
        {
            label: 'Save',
            onClick: handleSaveAndContinue,
            disabled: !quizDirty,
            className: 'btn-primary',
        },
        // {
        //     label: 'Duplicate Quiz',
        //     onClick: handleDuplicate,
        //     disabled: false,
        //     className: 'btn-primary',
        // },
        // {
        //     label: 'Share Quiz',
        //     onClick: handleConfirmShare,
        //     disabled: false,
        //     className: 'btn-primary',
        // },
        // {
        //     label: 'Add Question',
        //     onClick: handleCreateQuestion,
        //     disabled: false,
        //     className: 'btn-primary',
        // },
        // {
        //     label: 'Download Docx',
        //     onClick: () => window.open(`${process.env.REACT_APP_MUZOLOGY_API_URL}api/quiz/${quiz.id}/export/docx/`, '_blank'),
        //     disabled: false,
        //     className: 'btn-primary w-full',
        // },
        // {
        //     label: 'Test Quiz',
        //     onClick: () => {
        //     },
        //     disabled: true,
        //     className: 'btn-secondary',
        // },
        // // {
        // //     label: 'History',
        // //     onClick: () => {
        // //     },
        // //     disabled: true,
        // //     className: 'btn-secondary',
        // // },
        // {
        //     label: 'Generate Audio',
        //     onClick: handleGenerateAudio,
        //     disabled: false,
        //     className: 'btn-secondary',
        // },
        // {
        //     label: 'Delete',
        //     onClick: deleteQuiz,
        //     disabled: false,
        //     className: 'btn-error',
        // },
    ];

    const disabled = !quizDirty;
    const className = '';
    const [showLevels, setShowLevels] = useState([true, true, true, false])

    return (
        <div style={{padding: 2}} className={'flex flex-col'}>

            <div className={'bg-red-400- w-full p-2'}>
                <Button
                    // onClick={handleSaveAndContinue}
                    // disabled={disabled}
                    className={`btn btn-outline btn-sm ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
                >
                    save
                </Button>

                <Button
                    onClick={() => setShowLevels(levels => {
                        levels[0] = !levels[0];
                        return levels;
                    })}
                    // disabled={disabled}
                    className={`btn btn-outline btn-sm btn-primary`}
                >
                    {"Silver"}
                </Button>
                <Button
                    onClick={() => setShowLevels(levels => {
                        levels[1] = !levels[1];
                        return levels;
                    })}
                    // disabled={disabled}
                    className={`btn btn-outline btn-sm btn-primary`}
                >
                    {"Gold"}
                </Button>
                <Button
                    onClick={() => setShowLevels(levels => {
                        levels[2] = !levels[2];
                        return levels;
                    })}
                    // disabled={disabled}
                    className={`btn btn-outline btn-sm btn-primary`}
                >
                    {"Platinum"}
                </Button>

                <Button
                    onClick={() => setShowQuestionBank(value => !value)}
                    // disabled={disabled}
                    className={`btn btn-outline btn-sm btn-primary ${className}`}
                >
                    {"Question Bank"}
                </Button>


            </div>
            {/*<QuestionListEditor items={items} setItems={setItems}/>*/}
            <div style={{padding: 2}} className={'flex flex-row'}>

                <div style={{padding: 2}} className={'flex-grow w-full'}>
                    <DraggableDualList
                        showLevel1={showLevels[0]}
                        showLevel2={showLevels[0]}
                        showLevel3={showLevels[0]}
                        listLevel1={items1}
                        setListLevel1={setItems1}
                        listLevel2={items2}
                        setListLevel2={setItems2}
                        listLevel3={items3}
                        setListLevel3={setItems3}

                        showQuestionBank={showQuestionBank}
                        listBank={bank}
                        setListBank={setBank}
                    />
                </div>
                {/*
            <div style={{padding: 2}} className={'flex-grow w-[300px]'}>
                <QuizActions menuItems={menuItems}/>
                <QuizEditorMenu
                    handleSaveAndContinue={handleSaveAndContinue}
                    // handleDuplicate={handleDuplicate}
                    // handleConfirmShare={handleConfirmShare}
                    // handleCreateQuestion={handleCreateQuestion}
                    // handleGenerateAudio={handleGenerateAudio}
                    // deleteQuiz={deleteQuiz}
                    // quizDirty={quizDirty}
                    quiz={quiz}
                />
            </div>
                */}
            </div>
        </div>
    );
}
