import React from "react";

export const InfoRow = ({ name, value, errors }) => {
    const [showErrors, setShowErrors] = React.useState(true);

    const toggleShowErrors = () => {
        console.log('toggleShowErrors');
        // setShowErrors(value => !value)
        setShowErrors(true);
    }

    return (
        <>
            <tr className="cursor-pointer px-4 py-2 border-b last:border-none" onClick={() => setShowErrors(value => !value)}>
                <td className="px-4 py-2" onClick={toggleShowErrors}>
                    {name}
                </td>
                <td className="px-4 py-2 ">
                    {value}
                    {/*
                    {status ?
                        <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">PASS</span>
                        :
                        <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                    }
                    */}
                </td>
            </tr>
            {/*            {showErrors && errors?.length > 0 && <tr className="px-4 py-2 border-b last:border-none">
                <td className="px-4 py-2 w-full" colSpan={2}>
                    <div className="bg-red-200 text-red-700 px-2 py-1 rounded">
                        {errors.map((error, index) => {
                            return <div key={index}>{error}</div>
                        })}
                    </div>
                </td>
            </tr>}*/}
        </>
    );
};

export const addRow = (name, value) => {
    return (
        <InfoRow name={name} value={value} />
    )
}

export const addBoolRow = (name, value) => {
    return (
        <InfoRow name={name} value={value ? 'YES' : 'NO'} />
    )
}
