import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ReactSortable } from "react-sortablejs";

import { QuizChoiceLayouts } from "../../../../Components/QuizView/constants";
import { QuestionChoice } from "./ChoiceEditor/QuestionChoice";

const style = {
    border: "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: "white",
    cursor: "move"
};

const DraggableChoice = ({ id, text, index, moveCard, ...props }) => {
    const ref = useRef(null);
    const [, dropRef] = useDrop({
        accept: 'choice',
        // hover(item, monitor) {
        //     if (!ref.current) {
        //         return;
        //     }
        //     const dragIndex = item.index;
        //     const hoverIndex = index;
        //     // Don't replace items with themselves
        //     if (dragIndex === hoverIndex) {
        //         return;
        //     }
        //     // Determine rectangle on screen
        //     const hoverBoundingRect = ref.current.getBoundingClientRect();
        //     // Get vertical middle
        //     const hoverMiddleY =
        //         (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        //     // Determine mouse position
        //     const clientOffset = monitor.getClientOffset();
        //     // Get pixels to the top
        //     const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        //     // Only perform the move when the mouse has crossed half of the items height
        //     // When dragging downwards, only move when the cursor is below 50%
        //     // When dragging upwards, only move when the cursor is above 50%
        //     // Dragging downwards
        //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        //         return;
        //     }
        //     // Dragging upwards
        //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        //         return;
        //     }
        //     // Time to actually perform the action
        //     moveCard(dragIndex, hoverIndex);
        //     // Note: we're mutating the monitor item here!
        //     // Generally it's better to avoid mutations,
        //     // but it's good here for the sake of performance
        //     // to avoid expensive index searches.
        //     item.index = hoverIndex;
        // }
    });
    const [{ isDragging }, dragRef, previewRef] = useDrag({
        type: 'choice',
        item: { id, index, text },
        collect: monitor => ({ isDragging: monitor.isDragging() })
    });
    const opacity = isDragging ? 0 : 1;
    // previewRef(dropRef(ref));

    return (
        <QuestionChoice
            ref={dragRef}
            // ref={dropRef}
            style={{ ...style, opacity }}
            {...props}
        >
            <DragHandleIcon
                ref={dragRef}
            />
        </QuestionChoice>
    );
};

const SortableChoiceList = (props) => {
    return (
        <div>

        </div>
    );
};

const QuestionChoiceList = ({ question, onChangeLayout, handleAddMathChoice, handleAddTextChoice, handleAddImageChoice, handleChangeChoiceValue, deleteChoice, updateChoice, handleFreezeOrder }) => {
    const [order, setOrder] = useState(question?.choices?.map((choice, index) => choice.id) ?? []);
    // const [frozen, setFrozen] = useState(false)
    const frozen = useMemo( () => {
        // if all choices have last_position set, then the order is frozen
        return question?.choices?.every( choice => choice.last_position )
    }, [question?.choices]);
    // const handleDragEnd = event => {
    //     const { active, over } = event;
    //     console.log('handleDragEnd', active, over);
    //
    //     if (active.id !== over.id) {
    //         setOrder((items) => {
    //             const oldIndex = items.indexOf(active.id);
    //             const newIndex = items.indexOf(over.id);
    //             return arrayMove(items, oldIndex, newIndex);
    //         });
    //     }
    // };

    const updateChoiceOrder = (dragIndex, hoverIndex) => {
        console.log('updateChoiceOrder', dragIndex, hoverIndex);
    };

    const [state, setState] = useState(question?.choices ?? []);

    useEffect(() => {
        if (!question) {
            setOrder([]);
            return;
        }
        // sort choices by 'choice_index' field
        const choices = [...(question?.choices ?? [])].sort((a, b) => a.choice_index - b.choice_index);

        const choiceState = choices.map((choice, index) => {
            return {
                id: choice.id,
            };
        });
        setState(choiceState);

        // save the choice id order
        setOrder(choices.map((choice, index) => choice.id));
    }, [question]);

    return (
        <Stack spacing={2}>
            {state.map((item, index) => {
                const choice = question?.choices?.find(choice => choice.id === item.id);
                if (!choice)
                    return null;
                return (
                    <QuestionChoice
                        key={choice.id}
                        id={choice.id}
                        index={index}
                        question={question}
                        choice={choice}
                        deleteChoice={deleteChoice}
                        updateChoice={updateChoice}
                    >
                        <DragHandleIcon
                            // className={'drag-handle cursor-pointer'}
                            classes={{ root: 'drag-handle cursor-move' }}
                        />
                    </QuestionChoice>
                )
            })}
            {/*
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={order}>
            */}
            {/*
            <Stack spacing={2}>
                <ReactSortable
                    className={'flex flex-col gap-2'}
                    group="groupName"
                    list={state}
                    setList={setState}
                    animation={200}
                    delayOnTouchStart={true}
                    handle={'.drag-handle'}
                    delay={2}
                >
                    {state.map((item, index) => {
                        return <div>{item.id}</div>;
                    })}
                    {state.map((item, index) => {
                        const choice = question.choices.find(choice => choice.id === item.id);
                        if (!choice)
                            return null;
                        return (
                            <QuestionChoice
                                key={choice.id}
                                id={choice.id}
                                index={index}
                                question={question}
                                choice={choice}
                                handleChangeChoiceValue={handleChangeChoiceValue}
                                deleteChoice={deleteChoice}
                                updateChoice={updateChoice}
                            >
                                <DragHandleIcon
                                    // className={'drag-handle cursor-pointer'}
                                    classes={{ root: 'drag-handle cursor-move' }}
                                />
                            </QuestionChoice>
                        )
                    })}
                </ReactSortable>
            </Stack>
            */}

            <Stack direction={'row'} spacing={2}>
                <Stack direction={{ md: 'column', lg: 'row' }} spacing={2} sx={{ flexGrow: 1, flexShrink: 0 }}>
                    <Button variant={'outlined'} onClick={handleAddTextChoice} startIcon={<AddIcon />}>
                        Add Text Choice
                    </Button>
                    <Button variant={'outlined'} onClick={handleAddMathChoice} startIcon={<AddIcon />}>
                        Add Math Choice
                    </Button>
                    <Button variant={'outlined'} onClick={handleAddImageChoice} startIcon={<AddIcon />}>
                        Add Image Choice
                    </Button>
                    <Button variant={'outlined'} onClick={() => {
                        handleFreezeOrder(!frozen)
                    }} >
                        {frozen ? "UnFreeze Order" : "Freeze Order"}
                    </Button>
                    <FormControl>
                        <InputLabel id="question-type-select-label">Layout</InputLabel>
                        <Select
                            labelId="question-type-select-label"
                            id="question-type"
                            value={question?.layout ?? 'grid-1-col'}
                            label="Layout"
                            onChange={onChangeLayout}
                        >
                            {QuizChoiceLayouts.map((item) => {
                                return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default QuestionChoiceList;
