import React, {useMemo} from 'react';
import tw, {css, styled} from 'twin.macro';
import LessonGroupTree from "../LessonGroupTree";
import CourseTree from "../CourseTree";

const LibraryEditor = ({ course, createNewGroup, addVideo, removeVideo, showVideosPanel, showSettingsPanel, onSelectItem = null, selectedItem = null }) => {
    if( !course )
        return "Loading...";
    return (
        <div className='w-full h-full bg-[#ddd]'>
            <div className={'flex flex-row w-full h-full bg-[transparent]'}>
                <CourseTree
                    course={course}
                    createNewGroup={createNewGroup}
                    addVideo={addVideo}
                    removeVideo={removeVideo}
                    showVideosPanel={showVideosPanel}
                    showSettingsPanel={showSettingsPanel}
                    onSelectItem={onSelectItem}
                    selectedItem={selectedItem}
                />
            </div>
            {/*
            <div className={'flex flex-row p-4 bg-[yellow]'}>
                <div className={'flex flex-row bg-[green] flex-grow gap-4'}>
                    {trees}
                </div>
                <div className={'w-1/4 h-full bg-[#ddd]'}>

                </div>

                <div className={'w-3/4 h-full bg-[#ddd]'}>Right</div>
            </div>
                */}
        </div>
    );
};

export default LibraryEditor;
