/**
 * Created by jasonduncan on 9/15/16.
 */
import React, { Component } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import tw, { css, styled } from 'twin.macro';

/**
 * RichText - text that can contain embedded math and hashtags
 */

const MATH_TAG_OPEN = '<math>';
const MATH_TAG_CLOSE = '</math>';

const MathSpan = styled.span`
    .katex {
        white-space: normal !important;
    }
`;

class RichText extends Component {
    static defaultProps = {
        text: '',
        showMath: true,
        showHashTags: true,
        className: null,
        style: null,
        allMath: false
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.parseText(this.props.text);
    }

    // TODO: replace componentWillReceiveProps
    UNSAFE_componentWillReceiveProps(props) {
        this.parseText(props.text);
    }

    checkWrapperTag(text, tag) {
        if(!text) return null;
        let openTag = `<${tag}>`;
        let closeTag = `</${tag}>`;
        if (text.startsWith(openTag) && text.endsWith(closeTag)) {
            return text.substring(openTag.length, text.length - closeTag.length);
        }
        return null;
    }

    parseText(source) {
        if( !source ) {
            this.setState({ chunks: [] });
            return;
        }
        // parse the text into components
        let text = source;

        let chunks = [];

        // everything in this string is a math chunk
        if (this.props.allMath) {
            chunks.push({ type: 'math', text: text });
            this.setState({ chunks });
            return;
        }

        // TODO: convert this <center> nonsense to a format flag
        // first thing we want to do is see if the entire string is wrapped in <center> .. </center>
        let centered = this.checkWrapperTag(text, 'center');
        if (centered) {
            // parse this string only
            text = centered;
            centered = true;
        }

        while (text.length > 0) {
            let i = text.indexOf(MATH_TAG_OPEN);
            if (i === -1) {
                // done
                chunks.push({ type: 'text', text: text });
                // console.log( " remaining = " + text  );
                break;
            }

            // found an open tag
            let mathOpen = text.substring(i + MATH_TAG_OPEN.length);
            let j = mathOpen.indexOf(MATH_TAG_CLOSE);
            if (j === -1) {
                // invalid - no close tag
                chunks.push({ type: 'error', text: text });
                // console.log( " error = " + text  );
                break;
            }

            // create the text tag
            let pre = text.substring(0, i);
            chunks.push({ type: 'text', text: pre });

            // create the math tag
            let mathText = mathOpen.substring(0, j);
            chunks.push({ type: 'math', text: mathText });

            // increment past the tag and continue
            text = mathOpen.substring(j + MATH_TAG_CLOSE.length);
        }

        this.setState({ chunks, centered });
    }

    generateNodes() {
        let nodes = [];
        for (let i in this.state.chunks) {
            let chunk = this.state.chunks[i];
            switch (chunk.type) {
                default:
                case 'text':
                    nodes.push(
                        <span
                            key={i}
                            className='rich-text-text'
                            dangerouslySetInnerHTML={{ __html: chunk.text }}
                        />
                    );
                    break;

                case 'error':
                    nodes.push(
                        <span
                            key={i}
                            className='rich-text-error'>
	                        {chunk.text}
	                    </span>
                    );
                    break;

                case 'math':
                    // create the math tag
                    nodes.push(
                        <MathSpan
                            // css={[
                            //     css` .katex {
                            //         white-space: normal !important;
                            //     }`
                            // ]}
                            key={i}
                            className='rich-text-math'
                            // style={{ paddingLeft: '10px' }}
                        >
                            <InlineMath math={chunk.text} />
                        </MathSpan>
                    );
                    break;
            }
        }
        return nodes;
    }

    render() {
        // console.log('render rich text');
        return (
            <div
                className={`rich-text ${this.props.className}`}
                style={this.props.style}
                onClick={this.props.onClick}
            >
                {this.generateNodes()}
            </div>
        );
    }
}

export default RichText;
